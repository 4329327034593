import moment from "moment";
import React from "react";
import { FaCalendar, FaClock } from 'react-icons/fa6';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetNotificationQuery, useMarkAllAsReadMutation, useMarkAsReadMutation } from "../redux/apis/general";
import { format_date } from "../utils/helper";

const Notification = () => {

  const navigate = useNavigate()

  // const { notifications } = useSelector(state => state.generalSlice)
  const { user } = useSelector(state => state.userSlice)
  const { data: notifications, refetch } = useGetNotificationQuery({}, { refetchOnMountOrArgChange: true })
  const [update] = useMarkAsReadMutation()
  const [markAllAsRead] = useMarkAllAsReadMutation()

  const onClickNotification = item => {

    // update({ id: item?._id }).then(() => getNotification())

    if (item?.type === "order") {
      navigate(`/${user?.role}/orders/detail/${item?.metaData?.id}`)
    } else if (item?.type === "leaves") {
      navigate(`/${user?.role}/leaves/`)
    }

  }

  const markAllRead = () => {
    markAllAsRead().then(() => {
      refetch()
    })
  }

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2 style={{ padding: '26px 9px 30px' }}>Notifications</h2>
                </div>
                <div className="col-sm-6 col-9 text-right m-b-20 readd-bttn">
                  <a className="btn-read" onClick={markAllRead}>Read All</a>
                </div>
              </div>
              {
                notifications?.data?.map(item => (
                  <div className="row  ">
                    <div className="col-md-12">
                      <div className="col-md-12 col-sm-12  col-lg-12">
                        <div className="profile-01 cursor-pointer" onClick={() => onClickNotification(item)} style={{ backgroundColor: item?.status === "un-read" ? "#D3D3D3" : "#FFF" }}>
                          <div className="row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                              <div className="prof-par">
                                <p><span>{item?.notification?.title}</span></p>
                                <p>{item?.notification?.body}</p>
                              </div>
                              <div className="ico-prof">
                                <ul>
                                  <li><FaClock className="me-2" />{moment(item?.createdAt).fromNow()}</li>
                                  <li><FaCalendar className="me-2" />{format_date(item?.createdAt)}</li>
                                </ul>
                              </div>
                            </div>
                            {/* <div className="col-md-2">
                              <div className="profil-data">
                                <a href="#" className="btn btn btn-primary btn-rounded float-right oder prfl-btn">Mark as read</a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className="row">
                {/* <div class="col-md-12">
                        <div class="col-md-12 col-sm-12  col-lg-12">
                        <div class="profile-01">
                            <div class="row">
                            <div class="col-md-2">  
                            <div class="profile-img-01">
                                <a class="avtr-01" href="#"><img alt="" src="images/Image 55.png"></a>
                            </div>
                            </div>
                            <div class="col-md-8">
                                <div class="prof-par">
                                    <p><span>James Smith</span> added your photos to favorites</p>
                                </div>
                                <div class="ico-prof">
                                    <ul>
                                        <li><i class="fa-solid fa-clock"></i>02 Hours ago</li>
                                        <li><i class="fa-solid fa-calendar"></i>01/01/2022</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="profil-data">
                                    <a href="#" class="btn btn btn-primary btn-rounded float-right oder prfl-btn">Mark as read</a>
                                </div>
                            </div>
                            </div> 
                            </div>
                        </div>
                    </div> */}
              </div>
              {/* <div className="col-md-12">
                <div className="onlyy-btn">
                  <a className="show-all-btn" href="javascript:;">Show All</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Notification;