import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'comments/'

export const CommentApiService = createApi({
    reducerPath: 'commentApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createComment: builder.mutation({
            query: (payload) => {
                return {
                    url: `create`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        getComments: builder.query({
            query: (args) => {
                return 'get/' + args?.id
            },
            keepUnusedDataFor: 0
        })
    })
})

export const { useCreateCommentMutation, useGetCommentsQuery } = CommentApiService