import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateOrderMutation } from "../../../redux/apis/order";
import { useLazyFindCustomerQuery } from "../../../redux/apis/customer";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useUploadFileMutation } from "../../../redux/apis/general";
import { useGetSalesmanCommissionQuery } from "../../../redux/apis/commission";
import { useSelector } from "react-redux";

// const products = [
//   {
//     label: "Google Ads",
//     value: "google-adds",
//   },
//   {
//     label: "SEO",
//     value: "seo",
//   },
//   {
//     label: "Website",
//     value: "website",
//   },
// ];

const products = [
  {
    label: "Google Ads",
    value: "google-adds",
    // commission: 30,
  },
  {
    label: "SEO",
    value: "seo",
    // commission: 25,
  },
  {
    label: "Website",
    value: "website",
    // commission: 20,
  },
];

const defaultValues = {
  orderCreatedAt: new Date(),
  productName: products[0].value,
  organisationNumber: "",
  fullName: "",
  email: "",
  number: "0",
  agreementPeriod: "",
  orderValue: "",
  otherRequests: "",
  order_confirm_pdf: "",
  commissionA:"",
  commissionB:""
};

const OrderCreation = () => {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.userSlice)
  console.log(user,"useruseruser")
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ defaultValues });

  const allFields = watch();

  useEffect(() => {
    console.log("All fields:", allFields);
  }, [allFields]);
  let selectedProduct = watch("productName");

  const [upload,{ data:fileData, isSuccess :isSuccessFileData}] = useUploadFileMutation();


  const invoiceRef = useRef(null);
  const [findCustomer, { data, isSuccess }] = useLazyFindCustomerQuery();
  const [createOrder, { data: orderData, isSuccess: orderCreated, isLoading }] =
    useCreateOrderMutation();




    const { data : commissionSalesman , isSuccess :isCommissionSuccess } = useGetSalesmanCommissionQuery();
    console.log("commissionSalesman",commissionSalesman?.data)

  const onEnterOrganizationNumber = () => {
    findCustomer(getValues("organisationNumber"));
  };

  const onSubmit = async (data) => {
    createOrder(data);
  };

  useEffect(() => {
    if (isCommissionSuccess && commissionSalesman?.data) {
      setValue("commissionA", commissionSalesman.data.commissionA ? commissionSalesman.data.commissionA : user?.commissionA);
      setValue("commissionB", commissionSalesman.data.commissionB ? commissionSalesman.data.commissionB : user?.commissionB);
    }
  }, [isCommissionSuccess, commissionSalesman]);
  useEffect(() => {
    if (isSuccess) {
      setValue("fullName", data?.customer?.fullName);
      setValue("email", data?.customer?.email);
      setValue("phone", data?.customer?.phone);
      setValue("refPerson", data?.customer?.refPerson);
    } else {
      setValue("fullName", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("refPerson", "");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessFileData) {
      setValue("order_confirm_pdf", fileData?.imageUrl ? fileData?.imageUrl : "");
    }
  }, [isSuccessFileData]);


  useEffect(() => {
    if (orderCreated) {
      navigate("/salesman/orders");
    }
  }, [orderCreated]);

  
  const onChangeInvoice = (e) => {
    // setInvoice(e.target.files[0]);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    upload(formData);
  };

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: "10px 10px 50px" }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2 style={{ padding: "36px 9px 20px" }}>Order Creation</h2>
                  {/* <div className="btn-ordr">
                    <a className="btnn-112" href="/myorder">My orders</a>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="orderCreatedAt"
                      control={control}
                      rules={{
                        required: "Order Date is required",
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Order Date"
                          placeholder="Enter Order Date"
                          type="date"
                          error={errors?.orderCreatedAt?.message}
                          // required
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="productName"
                      control={control}
                      rules={{
                        required: "Product is required",
                      }}
                      render={({ field }) => (
                        <div className="md-form">
                          <h6>
                            Product <span className="text-danger">*</span>
                          </h6>
                          <select className="form-control" {...field}>
                            {products.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </select>
                        </div>
                      )}
                    />
                  </div>
                
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="organisationNumber"
                        control={control}
                        rules={{
                          required: "Organization Number is required",
                        }}
                        render={({ field }) => (
                          <InputField
                            label="Organization Number"
                            placeholder="Enter Organization Number"
                            required
                            error={errors?.organisationNumber?.message}
                            {...field}
                            onBlur={onEnterOrganizationNumber}
                          />
                        )}
                      />
                    </div>
                
              
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="fullName"
                        control={control}
                        rules={{
                          required: "Customer Name is required",
                        }}
                        render={({ field }) => (
                          <InputField
                            label="Customer Name"
                            placeholder="Enter Customer Name"
                            error={errors?.fullName?.message}
                            required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
              
               
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="email"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer Email"
                            placeholder="Enter Customer Email"
                            error={errors?.email?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
             

                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="phone"
                      control={control}
                      // rules={{
                      //   required: "Customer Email is required"
                      // }}
                      render={({ field }) => (
                        <InputField
                          label="Customer Phone"
                          placeholder="Enter Customer Phone"
                          error={errors?.phone?.message}
                          // required
                          {...field}
                          disabled={isSuccess}
                        />
                      )}
                    />
                  </div>
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="old_link"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer old webbpage link"
                            placeholder="Customer old webbpage link"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_services"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customers services"
                            placeholder="Customers services"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                   {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="haveDomain"
                        control={control}
                        render={({ field }) => (
                          <div className="md-form">
                            <h6>
                              Have Domain? <span className="text-danger">*</span>
                            </h6>
                            <select className="form-control" {...field}>
                              <option value="yes" defaultChecked>YES</option>
                              <option value="no">NO</option>
                            </select>
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_domain"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer Domain"
                            placeholder="Customer Domain"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                 
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_type"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customers type"
                            placeholder="Customers type"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_social_media"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customers Social media"
                            placeholder="Customers Social media"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="refPerson"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer Ref Person"
                            placeholder="Enter Customer Ref Person"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                 
                  {selectedProduct !== "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="number"
                        control={control}
                        rules={{
                          required: "No of clicks is required",
                        }}
                        render={({ field }) => (
                          <InputField
                            label={
                              selectedProduct !== "seo"
                                ? "Number of Clicks"
                                : "Number of Keywords"
                            }
                            placeholder={`Enter ${
                              selectedProduct !== "seo"
                                ? "Number of Clicks"
                                : "Number of Keywords"
                            }`}
                            error={errors?.number?.message}
                            required
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}
                 
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="agreementPeriod"
                        control={control}
                        rules={{
                          required: "Agreement Period is required",
                        }}
                        render={({ field }) => (
                          <InputField
                            label="Agreement Period (Monthly)"
                            placeholder="Enter Agreement Period"
                            error={errors?.agreementPeriod?.message}
                            required
                            {...field}
                          />
                        )}
                      />
                    </div>
              
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="orderValue"
                      control={control}
                      rules={{
                        required: "Order Value is required",
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Order Value"
                          placeholder="Enter Order Value"
                          error={errors?.orderValue?.message}
                          required
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="otherRequests"
                      control={control}
                      // rules={{
                      //   required: "Other Request is required"
                      // }}
                      render={({ field }) => (
                        <InputField
                          label="Other Requests"
                          placeholder="Enter Other Request"
                          // required
                          error={errors?.otherRequests?.message}
                          type="textarea"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="invoice"
                      // rules={{
                      //   required: "Order confirm invoice is required",
                      // }}
                      control={control}
                      render={({ field }) => (
                        <div className="md-form">
                          <h6>
                            Add Order Confirm
                          </h6>
                          <input
                            type="file"
                            // required
                            className="form-control"
                            ref={invoiceRef}
                            accept=".pdf"
                            onChange={(e) => {
                              field.onChange(e);
                              onChangeInvoice(e);
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                 
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commissionA"
                      control={control}
                      defaultValue={user?.commissionA}
                      render={({ field }) => (
                        <InputField
                          label="Commission A Customer"
                          placeholder="Commission A Customer"
                          error={errors?.commissionA?.message}
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commissionB"
                      control={control}
                      defaultValue={user?.commissionB}
                     
                      render={({ field }) => (
                        <InputField
                          label="Commission B Customer"
                          placeholder="Commission B Customer"
                          error={errors?.commissionB?.message}
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </div>

                  {/* <div className="d-flex justify-content-end mb-3">
                <label>Add Invoice</label>
                <input
                  ref={invoiceRef}
                  type="file"
                  onChange={onChangeInvoice}
                />
              </div> */}
                  {/* <button className="btn btn-danger bg-danger">Upload PDF</button> */}
                  <div className="col-md-12">
                    <Button
                      text="Submit"
                      onClick={handleSubmit(onSubmit)}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OrderCreation;
