import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import popup from "../../../assests/popup.png"
import { FaArrowLeft } from 'react-icons/fa6';
import { Controller, useForm } from "react-hook-form";
import { useAddUserMutation } from "../../../redux/apis/user";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCustomerByIdQuery, useUpdateCustomerMutation } from "../../../redux/apis/customer";
import { Spinner } from "react-bootstrap";

const EditCustomer = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm();

    const { data: customerData, isLoading: gettingCustomer } = useGetCustomerByIdQuery(id)
    const [editCustomer, { data, isLoading, isSuccess }] = useUpdateCustomerMutation()
    const [newCustomer, setNewCustomer] = useState()

    useEffect(() => {
        if (customerData) {
            setValue('organisationNumber', customerData?.data?.organisationNumber)
            setValue('fullName', customerData?.data?.fullName)
            setValue('phone', customerData?.data?.phone)
            setValue('email', customerData?.data?.email)
            setValue('refPerson', customerData?.data?.refPerson)
            setNewCustomer(customerData?.data?.newCustomer)
        }
    }, [customerData])

    const onSubmit = data => {
        editCustomer({ ...data, _id: id, newCustomer })
    }

    useEffect(() => {
        if (isSuccess) {
            reset()
            navigate('/admin/customers')
            toast.success("Customer Updated Successfully")
        }
    }, [isSuccess])

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2><a href="/admin/customers" className="btn back"><FaArrowLeft /></a>Edit Customer</h2>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    gettingCustomer ? <div className="col-12 d-flex justify-content-center align-items-center mt-3"><Spinner /></div> :
                                        <div className="col-md-12">
                                            <div className="col-sm-5 ofset-7">
                                                <Controller
                                                    name="organisationNumber"
                                                    control={control}
                                                    rules={{
                                                        required: "Organization Number is required"
                                                    }}
                                                    render={({ field }) => (
                                                        <InputField
                                                            disabled
                                                            label={"Organization Number"}
                                                            placeholder="Enter Organization Number"
                                                            error={errors?.organisationNumber?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-sm-5 ofset-7 d-flex justify-content-center align-items-center mt-3">
                                                <input type="checkbox" checked={newCustomer} onChange={() => setNewCustomer(true)} />
                                                <label className="ms-2 mb-0">A Customer</label>
                                                <input type="checkbox" className="ms-2" checked={!newCustomer} onChange={() => setNewCustomer(false)} />
                                                <label className="ms-2 mb-0">B Customer</label>
                                            </div>
                                            <div className="col-sm-5 ofset-7">
                                                <Controller
                                                    name="fullName"
                                                    control={control}
                                                    rules={{
                                                        required: "Full Name is required"
                                                    }}
                                                    render={({ field }) => (
                                                        <InputField
                                                            label={"Full Name"}
                                                            placeholder="Enter Full Name"
                                                            error={errors?.fullName?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-sm-5 ofset-7">
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    // rules={{
                                                    //     required: "Phone Number is required"
                                                    // }}
                                                    render={({ field }) => (
                                                        <InputField
                                                            label={"Phone Number"}
                                                            placeholder="Enter Phone Number"
                                                            error={errors?.phone?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-sm-5 ofset-7">
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    // rules={{
                                                    //     required: "Email is required"
                                                    // }}
                                                    render={({ field }) => (
                                                        <InputField
                                                            label={"Email Address"}
                                                            placeholder="Enter Email Address"
                                                            error={errors?.email?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-sm-5 ofset-7">
                                                <Controller
                                                    name="refPerson"
                                                    control={control}
                                                    // rules={{
                                                    //     required: "Ref. Person is required"
                                                    // }}
                                                    render={({ field }) => (
                                                        <InputField
                                                            label={"Ref. Person"}
                                                            placeholder="Enter Ref. Person"
                                                            error={errors?.refPerson?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <Button
                                                    loading={isLoading}
                                                    text="Update"
                                                    onClick={handleSubmit(onSubmit)}
                                                />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            </div>
        </div>
    );
}
export default EditCustomer;