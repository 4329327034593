import React, { useEffect } from "react";
import { toast } from "react-toastify";
import popup from "../../../assests/popup.png"
import { FaArrowLeft } from 'react-icons/fa6';
import { Controller, useForm } from "react-hook-form";
import { useAddUserMutation } from "../../../redux/apis/user";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";

const AddSalesman = () => {

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const [addSalesman, { data, isLoading, isSuccess }] = useAddUserMutation()

  const onSubmit = data => {
    addSalesman(data)
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success("Invitation Successfully sent to Salesman")
      reset()
    }
  }, [isSuccess])

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2><a href="/admin/salesman" className="btn back"><FaArrowLeft /></a>Add Salesman</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="fullName"
                      control={control}
                      rules={{
                        required: "Full Name is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Full Name"}
                          placeholder="Enter Full Name"
                          error={errors?.fullName?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "Phone Number is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Phone Number"}
                          placeholder="Enter Phone Number"
                          error={errors?.phoneNumber?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Email Address"}
                          placeholder="Enter Email Address"
                          error={errors?.email?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commissionA"
                      control={control}
                      defaultValue={"30"}
                      rules={{
                        required: "Commission A Customer type is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Commission A"}
                          placeholder="Enter Commission Customer A type"
                          error={errors?.commission?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="commissionB"
                      control={control}
                      defaultValue={"30"}
                      rules={{
                        required: "Commission B Customer type is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Commission B"}
                          placeholder="Enter Commission Customer B type"
                          error={errors?.commission?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12">
                    <Button
                      loading={isLoading}
                      text="Send Invite"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default AddSalesman;