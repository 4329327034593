import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../../utils/constants";

const baseUrl = BASE_URL + "attendance/";

export const AttendanceApiService = createApi({
  reducerPath: "attendanceApiService",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      let { token } = getState().userSlice;
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAttendance: builder.query({
      query: (payload) => {
        if (payload) {
          let { search, page, rowsPerPage, createdBy } = payload;

          return `todays?${page && page !== "" ? `page=${page}` : ""}${
            rowsPerPage && rowsPerPage !== ""
              ? `&rowsPerPage=${rowsPerPage}`
              : ""
          }${search && search !== "" ? `&search=${search}` : ""}${
            createdBy && createdBy !== "" ? `&createdBy=${createdBy}` : ""
          }`;
        } else {
          return `todays?`;
        }
      },
      keepUnusedDataFor: 0,
    }),
    getSalesmanAttendance: builder.query({
      query: (payload) => {
        if (payload) {
          let { from, to, salesmanId } = payload;

          return `all?${from && from !== "" ? `from=${from}` : ""}${
            to && to !== "" ? `&to=${to}` : ""
          }${
            salesmanId && salesmanId !== "" ? `&salesmanId=${salesmanId}` : ""
          }`;
        } else {
          return `all`;
        }
      },
      keepUnusedDataFor: 0,
    }),
    updateAttendance: builder.mutation({
      query: (data) => {
        if (data?.type === "update") {
          return {
            method: "PUT",
            url: `update/${data?.id}`,
            body: data?.payload,
          };
        } else if (data?.type === "approve") {
          return {
            method: "PUT",
            url: `approve/${data?.id}`,
            body: data,
          };
        } else if (data?.type === "create") {
          return {
            method: "POST",
            url: `create`,
            body: data?.payload,
          };
        }
      },
    }),
  }),
});

export const {
  useGetAttendanceQuery,
  useGetSalesmanAttendanceQuery,
  useUpdateAttendanceMutation,
} = AttendanceApiService;
