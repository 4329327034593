import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'auth/'

export const AuthApiService = createApi({
    reducerPath: 'authApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState()?.userSlice
            if (token) {
                headers.set('Authorization', token)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: 'login',
                method: 'POST',
                body: { ...payload, fcmToken: localStorage.getItem("device_token") }
            }),
        }),
        forgetPassword: builder.mutation({
            query: (payload) => ({
                url: 'forget-password',
                method: 'POST',
                body: payload
            }),
        }),
        verifyOtp: builder.mutation({
            query: (payload) => ({
                url: 'verify-otp',
                method: 'POST',
                body: payload
            }),
        }),
        setPassword: builder.mutation({
            query: (payload) => ({
                url: 'reset-password',
                method: 'POST',
                body: payload
            }),
        }),
        logout: builder.mutation({
            query: (payload) => ({
                url: 'logout',
                method: 'POST',
                body: payload
            }),
        }),
    }),
})

export const { useLoginMutation, useForgetPasswordMutation, useSetPasswordMutation, useVerifyOtpMutation, useLogoutMutation } = AuthApiService