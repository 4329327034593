import React, { useEffect } from "react";
import popup from "../../../assests/popup.png"
import { FaSort } from 'react-icons/fa6';
import { FaEllipsisV, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import useTableControls from "../../../hooks/useTableControls";
import { useGetUsersQuery, useUpdateUserMutation } from "../../../redux/apis/user";
import { format_date } from "../../../utils/helper";
import Pagination from "../../../components/Table/Pagination";
import Table from "../../../components/Table";

const Salesman = () => {

    const navigate = useNavigate()

    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()
    const { data, refetch, isLoading } = useGetUsersQuery({ page, rowsPerPage, search })
    const [updateUser, { isSuccess }] = useUpdateUserMutation()

    useEffect(() => {
        refetch()
    }, [isLoading, isSuccess])

    const onPageChange = page => setPage(page)

    const onStatusChange = (item) => {
        updateUser({ id: item?._id, active: !(item?.active) })
    }

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2>Salesman Management</h2>
                                </div>
                                <div className="col-sm-6 col-9 m-b-20" >
                                    <a href="/admin/salesman/add" className="btn btn btn-primary btn-rounded float-right oder text-white">Add Salesman</a>
                                    <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <Table
                                headings={['S. No', 'Username', 'Email', "Registration Date","Commission A","Commission B", "Status", "Action", "View","Update"]}
                                data={data}
                                loading={isLoading}
                                length={data?.data?.length}
                            >

                                {
                                    data?.data?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.email}</td>
                                            <td>{format_date(item?.createdAt)}</td>
                                            <td>{item?.commissionA|| 0}</td>
                                            <td>{item?.commissionB || 0}</td>
                                            
                                            <td >{item?.active ? "Active" : "Inactive"}</td>
                                            <td
                                                onClick={() => onStatusChange(item)}
                                                className={`${item?.active ? "text-danger" : "text-success"} text-decoration-underline cursor-pointer`}>{item?.active ? "Mark as Inactive" : "Mark as Active"}</td>
                                            <td onClick={() => navigate(`/admin/salesman/detail/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">View</td>
                                            <td onClick={() => navigate(`/admin/salesman/edit/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">Edit</td>
                                        </tr>
                                    ))
                                }
                            </Table>
                            {
                                data?.data?.length > 0 &&
                                <Pagination
                                    length={data?.data?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </div>
                    </div>
                    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>

    );
}
export default Salesman;