import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const ProtectedRoute = ({ children }) => {

    const navigate = useNavigate()
    const { loggedIn, user } = useSelector(state => state.userSlice)

    useEffect(() => {
        if (loggedIn) {
            navigate(`/${user?.role}/`)
        } else {
            // navigate("/login")
        }
    }, [])

    return children
}

export default ProtectedRoute