import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'user/'

export const UserApiService = createApi({
    reducerPath: 'userApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        addUser: builder.mutation({
            query: (payload) => {
                return {
                    url: `create`,
                    method: 'POST',
                    body: { ...payload, role: "salesman" }
                }
            }
        }),
        getUsers: builder.query({
            query: (payload) => {
                if (payload) {
                    let { search, page, rowsPerPage, active } = payload

                    return `get?role=salesman${search && search !== '' ? `&search=${search}` : ""}${page && page !== '' ? `&page=${page}` : ""}${rowsPerPage && rowsPerPage !== '' ? `&rowsPerPage=${rowsPerPage}` : ""}${active !== undefined && active !== '' ? `&active=${active}` : ""}`
                } else {
                    return `get?role=salesman`
                }
            },
            keepUnusedDataFor: 0
        }),
        getUserById: builder.query({
            query: (id) => `get/${id}`
        }),
        updateUser: builder.mutation({
            query: (payload) => {

                let data = { ...payload }
                delete data.id

                return {
                    url: `update/${payload.id}`,
                    method: 'PUT',
                    body: data
                }
            }
        }),
        changePassword: builder.mutation({
            query: (payload) => {
                return {
                    url: `change-password`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        getAdminDashboard: builder.query({
            query: (data) => {
                if (data?.role === "admin") {
                    return `adminDashboard?type=${data?.type}&currentMonthEndDate=${data?.currentMonthEndDate}&currentMonthStartDate=${data?.currentMonthStartDate}&previousMonthEndDate=${data?.previousMonthEndDate}&previousMonthStartDate=${data?.previousMonthStartDate}${data?.selectedSalesman !== null ? `&selectedSalesman=${data?.selectedSalesman}` : ""}`
                } else {
                    return `salesmanStats?type=${data?.type}&currentMonthEndDate=${data?.currentMonthEndDate}&currentMonthStartDate=${data?.currentMonthStartDate}&previousMonthEndDate=${data?.previousMonthEndDate}&previousMonthStartDate=${data?.previousMonthStartDate}${data?.selectedSalesman !== null ? `&selectedSalesman=${data?.selectedSalesman}` : ""}`
                }
            },
            keepUnusedDataFor: 0
        }),
        getSalesmanDashboard: builder.query({
            query: (data) => `salesmanStats?type=${data?.type}`,
            keepUnusedDataFor: 0
        }),
    })
})

export const { useGetUsersQuery, useAddUserMutation, useGetAdminDashboardQuery, useGetSalesmanDashboardQuery, useUpdateUserMutation, useGetUserByIdQuery, useChangePasswordMutation } = UserApiService