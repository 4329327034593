import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import {
  useGetOrdersQuery,
  useMarkAsPaidMutation,
} from "../../../redux/apis/order";
import { CURRENCY, amountFormatter, format_date } from "../../../utils/helper";

const PaidOrders = () => {
  const navigate = useNavigate();

  const { settings } = useSelector((State) => State.generalSlice);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] =
    useTableControls();
  const { data, isLoading, refetch } = useGetOrdersQuery({
    page,
    rowsPerPage,
    search,
    from,
    to,
    status: "paid",
  });
  const [changeStatus, { isSuccess }] = useMarkAsPaidMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      toast.success("Status Updated Successfully");
    }
  }, [isSuccess]);

  const onPageChange = (page) => setPage(page);

  const onChangeStatus = (id, status) => {
    changeStatus({ id, status });
  };

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                {/* <div className="col-sm-4 col-3 main-heading-1">
                                    <h2>Paid Orders</h2>
                                </div>
                                <div className="col-sm-6 col-9 m-b-20" >
                                    <input type="search" name className="search-1" placeholder="Search Here...." />
                                </div> */}
                <div className="col-sm-7 col-5 m-b-20 mb-5">
                  <h2>Paid Orders</h2>
                  {/* <a href="add-salesman" className="btn btn btn-primary btn-rounded float-right oder">All Orders</a> */}
                  <input
                    type="search"
                    name
                    className="search-1 mx-1"
                    placeholder="Search Name...."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <input
                    type="date"
                    name
                    className="search-1 mx-1"
                    placeholder="Search Here...."
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    max={to}
                  />
                  <input
                    type="date"
                    name
                    className="search-1 mx-1"
                    placeholder="Search Here...."
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    min={from}
                  />
                </div>
              </div>
              <Table
                headings={[
                  "S. No",
                  "Salesman Name",
                  "Customer Name",
                  "Price",
                  "Registration Date",
                  "Order Paid Date",
                  "Status",
                  "Action",
                ]}
                data={data}
                loading={isLoading}
                length={data?.data?.length}
                className="mt-5"
              >
                {data?.data?.map((item, index) => (
                  <tr>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.createdBy?.fullName}</td>
                    <td>{item?.customer?.fullName}</td>
                    <td>
                      {amountFormatter(item?.orderValue)} {CURRENCY}
                    </td>
                    <td>{format_date(item?.createdAt)}</td>
                    <td>{format_date(item?.orderPaidOn)}</td>
                    <td className={`text-capitalize text-success`}>
                      {item?.status}
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/admin/orders/detail/${item?._id}`)
                      }
                      className="text-primary text-decoration-underline cursor-pointer"
                    >
                      View
                    </td>
                    {/* <td className="text-right">
                                                                <div className="dropdown dropdown-action">
                                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><FaEllipsisV /></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><FaTrashAlt /> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                  </tr>
                ))}
              </Table>
            </div>
            <Pagination
              length={data?.data?.length}
              totalDocs={data?.total}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </div>
          {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};
export default PaidOrders;
