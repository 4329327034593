// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCC3oohGfVpgAMXCDe7tFcfeVMDDVDFTrA",
    authDomain: "stockholm-a4df2.firebaseapp.com",
    projectId: "stockholm-a4df2",
    storageBucket: "stockholm-a4df2.appspot.com",
    messagingSenderId: "605826510592",
    appId: "1:605826510592:web:f4cd98c4883e00228b8de5"
};

// Initialize Firebase
let app = firebase.initializeApp(firebaseConfig);

export default app