import moment from "moment";
import React, { useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBell } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa6';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import media from "../assests/Media.svg";
import placeholder from "../assests/placeholder.jpeg";
import { useLogoutMutation } from "../redux/apis/auth";
import { signout } from "../redux/slice/userSlice";

const Header = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.userSlice)
  const { notifications, unreadCount } = useSelector(state => state.generalSlice)

  const [logoutUser, { isSuccess }] = useLogoutMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(signout())
    }
  }, [isSuccess])

  const onLogout = () => {
    logoutUser()
  }

  const onClickNotification = item => {

    if (item?.type === "order") {
      navigate(`/${user?.role}/orders/detail/${item?.metaData?.id}`)
    } else if (item?.type === "leaves") {
      navigate(`/${user?.role}/leaves/`)
    }

  }

  return (
    <div>
      <div className="header">
        <div className="header-left">
          <a href="index-2.html" className="logo">
            {/* <img src={media} alt="" className="logo" /> */}
          </a>
        </div>
        {/* <a id="toggle_btn" href="javascript:void(0);"><i class="fa fa-bars"></i></a> */}
        <a id="mobile_btn" className="mobile_btn float-left" href="#sidebar"><FaBars /></a>
        <ul className="nav user-menu float-right">
          <li className="nav-item dropdown d-none d-sm-block">
            <Dropdown>
              <Dropdown.Toggle style={{ width: '70px', backgroundColor: '#F7F7F7' }}>
                <FaBell color="#224393" /> <span className="badge badge-pill bg-danger float-right">{unreadCount > 10 ? "10+" : unreadCount}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <span className="ms-2">Notifications</span>
                {
                  notifications?.length > 0 ? (
                    <>
                      {
                        notifications?.slice(0, 3)?.map(item => (
                          <Dropdown.Item>
                            <div className="media cursor-pointer" onClick={() => onClickNotification(item)}>
                              <span className="avatar">
                                <FaBell />
                              </span>
                              <div className="media-body">
                                <h5 className="noti-head">{item?.notification?.title} </h5>
                                <p className="noti-details"><span className="noti-title">{item?.notification?.body}</span></p>
                                <p className="noti-time"><span className="notification-time text-capitalize">{moment(item?.createdAt).fromNow()}</span></p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ))
                      }
                      <Dropdown.Item >
                        <Link to={`/${user?.role}/notification`} style={{ color: 'black' }}>View all</Link>
                      </Dropdown.Item>
                    </>

                  ) : (
                    <Dropdown.Item >
                      <h4 style={{ color: 'black' }}>No New Notifications</h4>
                    </Dropdown.Item>
                  )
                }

              </Dropdown.Menu>
            </Dropdown>
          </li>
          {/*                 <li class="nav-item dropdown d-none d-sm-block">
                <a href="javascript:void(0);" id="open_msg_box" class="hasnotifications nav-link"><i class="fa fa-comment-o"></i> <span class="badge badge-pill bg-danger float-right">8</span></a>
            </li> */}
          <li className="nav-item dropdown has-arrow">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <img className="rounded-circle me-2" src={user?.image || placeholder} width={24} alt="Admin" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = placeholder;
                }} />
                <span>{user?.fullName}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item ><Link to={`/${user?.role}/profile`} style={{ color: 'black' }}>My Profile</Link></Dropdown.Item>
                <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </div>
  );
};


export default Header;