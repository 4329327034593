import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.scss';
import { FaBasketShopping, FaFileLines, FaPerson, FaSackDollar } from 'react-icons/fa6';
import { AiOutlineDashboard } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { FaBell, FaCheck, FaIntercom, FaPen, FaPersonBooth,FaTrash  } from 'react-icons/fa';

const ADMIN_LINKS = [
    {
        display: 'Dashboard',
        icon: <AiOutlineDashboard />,
        to: '/admin',
        section: '/admin'
    },
    {
        display: 'Salesman',
        icon: <FaPerson />,
        to: '/admin/salesman',
        section: '/admin/salesman'
    },
    {
        display: 'Customers',
        icon: <FaPersonBooth />,
        to: '/admin/customers',
        section: '/admin/customers'
    },
    {
        display: 'Orders',
        icon: <FaBasketShopping />,
        to: '/admin/orders',
        section: '/admin/orders'
    },
    {
        display: 'Paid Orders',
        icon: <FaBasketShopping />,
        to: '/admin/paid-orders',
        section: '/admin/paid-orders'
    },
    {
        display: 'Budget',
        icon: <FaFileLines />,
        to: '/admin/budget',
        section: '/admin/budget'
    },
    {
        display: 'Salary',
        icon: <FaSackDollar />,
        to: '/admin/salary',
        section: '/admin/salary'
    },
    {
        display: 'Attendance',
        icon: <FaCheck />,
        to: '/admin/attendance',
        section: '/admin/attendance'
    },
    {
        display: 'Leaves',
        icon: <FaBell />,
        to: '/admin/leaves',
        section: '/admin/leaves'
    },
    {
        display: 'Commission',
        icon: <FaSackDollar />,
        to: '/admin/commission',
        section: '/admin/commission'
    },
    {
        display: 'Bin',
        icon: <FaTrash />,
        to: '/admin/orders-bin',
        section: '/admin/orders-bin'
    },
]

const SALESMAN_LINKS = [
    {
        display: 'Dashboard',
        icon: <AiOutlineDashboard />,
        to: '/salesman/',
        section: '/salesman/'
    },
    {
        display: 'Customers',
        icon: <FaPerson />,
        to: '/salesman/customers',
        section: '/salesman/customers'
    },
    {
        display: 'Orders',
        icon: <FaBasketShopping />,
        to: '/salesman/orders',
        section: '/salesman/orders'
    },
    {
        display: 'Create Order',
        icon: <FaPen />,
        to: '/salesman/orders/create',
        section: '/salesman/orders/create'
    },
    {
        display: 'Budget',
        icon: <FaFileLines />,
        to: '/salesman/budget',
        section: '/salesman/budget'
    },
    {
        display: 'Salary',
        icon: <FaSackDollar />,
        to: '/salesman/salary',
        section: '/salesman/salary'
    },
    {
        display: 'Attendance',
        icon: <FaCheck />,
        to: '/salesman/attendance',
        section: '/salesman/attendance'
    },
    {
        display: 'Leave',
        icon: <FaBell />,
        to: '/salesman/leaves',
        section: '/salesman/leaves'
    },
]

const EMPLOYEE_LINKS = [
    {
        display: 'Paid Orders',
        icon: <FaBasketShopping />,
        to: '/employee/',
        section: '/employee/'
    },
    {
        display: 'Salary',
        icon: <FaSackDollar />,
        to: '/employee/salary',
        section: '/employee/salary'
    },
]

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();

    const { user, loggedIn } = useSelector(state => state.userSlice)

    // useEffect(() => {
    //     if (loggedIn) {
    //         setTimeout(() => {
    //             const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
    //             indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
    //             setStepHeight(sidebarItem.clientHeight);
    //         }, 50);
    //     }
    // }, []);

    // change active index
    useEffect(() => {
        const curPath = window.location.pathname.split('/').join('/')
        console.log("cur", curPath)
        const activeItem = (user?.role === "salesman" ? SALESMAN_LINKS : user?.role === "employee" ? EMPLOYEE_LINKS : ADMIN_LINKS).findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return <div className='sidebar'>
        <div ref={sidebarRef} className="sidebar__menu">
            {
                (
                    user?.role === "salesman" ? SALESMAN_LINKS : user?.role === "employee" ? EMPLOYEE_LINKS : ADMIN_LINKS).map((item, index) => (
                        <Link to={item.to} key={index}>
                            <div className={`sidebar__menu__item ${window.location.pathname === item.section ? 'bg-white active' : ''}`}>
                                <div className="sidebar__menu__item__icon">
                                    {item.icon}
                                </div>
                                <div className="sidebar__menu__item__text">
                                    {item.display}
                                </div>
                            </div>
                        </Link>
                    ))
            }
        </div>
    </div>;
};

export default Sidebar;
