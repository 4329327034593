import moment from "moment";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaArrowLeft } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import Table from "../../../components/Table";
import {
  useGetSalesmanAttendanceQuery,
  useUpdateAttendanceMutation,
} from "../../../redux/apis/attendance";
import { useGetUserByIdQuery } from "../../../redux/apis/user";
import { FRIDAY_WORKING_HOURS, WORKING_HOURS } from "../../../utils/constants";
import {
  DAYS,
  calculate_salary_percent,
  getMonthYear,
  getMonths,
  monthlyIntervalDate,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";

const AttendanceDetail = () => {
  const d = getMonthYear();
  const { id } = useParams();

  const [approvedData, setApprovedData] = useState({
    isApproved: false,
    comment: "",
    id: "",
    type: "",
    title: "",
    salesmanId: "",
    isEdit: "",
    date: "",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let selectForApprove = (item, title) => {
    console.log(item, "itemss");
    handleShow();
    setApprovedData({
      type: "approve",
      isApproved:
        title === "absent"
          ? item?.isAbsentApproved
          : title === "early"
          ? item?.isEarlyApproved
          : title === "late"
          ? item?.isLateApproved
          : false,
      comment:
        title === "absent"
          ? item?.AbsentApproveComment
          : title === "early"
          ? item?.EarlyApproveComment
          : title === "late"
          ? item?.lateApproveComment
          : "-",

      id: details?._id,
      title: title,
      date: item?.date,
    });
  };
  const [month, setMonth] = useState(d.month);
  const [year, setYear] = useState(d.year);

  let [dataToShow, setDataToShow] = useState([]);
  let [dateData, setDateData] = useState(monthlyIntervalDate());
  let [thisMonthTotalWorkingPercentage, setThisMonthTotalWorkingPercentage] =
    useState(0.0);
  console.log(dataToShow, "dataToShow");
  const [update, { data: updateRes, isSuccess: updateSuccess }] =
    useUpdateAttendanceMutation();

  const { data, refetch, isLoading, isFetching } =
    useGetSalesmanAttendanceQuery({
      from: dateData.from,
      to: dateData.to,
      salesmanId: id,
    });
  const { data: salesman } = useGetUserByIdQuery(id);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(updateRes?.message);
      refetch();
      setEditIndex();
      setEditedData();

      setShow(false);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (month) {
      let data = monthlyIntervalDate(month);
      setDateData(data);
    }
  }, [month]);

  if (salesman) {
    var details = salesman?.data;
  }

  useEffect(() => {
    if (data) {
      let thisMonthTotalWorkingHours = 0;
      let noOfBusinessHours = 0;

      let temp = dateData?.monthlyDates?.map((item) => {
        let date = item;
        let day = moment(date, "DD-MM-YYYY").day();

        let isWeekend = day === 0 || day === 6;

        let dataDate = null,
          details = null;

        if (!isWeekend) {
          noOfBusinessHours += day === 5 ? FRIDAY_WORKING_HOURS : WORKING_HOURS;
          dataDate = data?.data?.map((item) => {
            if (item) {
              if (moment(item?.checkInTime).format("DD-MM-YYYY") == date) {
                let thisAttendance = item;
                console.log(thisAttendance, "thisAttendance");
                let checkin = thisAttendance?.checkInTime;
                let checkout = thisAttendance?.checkoutTime;

                thisMonthTotalWorkingHours += thisAttendance?.duration
                  ? thisAttendance?.duration
                  : 0;
                console.log(thisAttendance, "thisAttendancethisAttendance");

                return {
                  _id: thisAttendance?._id,
                  checkInTime: checkin,
                  checkoutTime: checkout,
                  // percentage: thisAttendance?.checkoutTime
                  //   ? calculate_salary_percent(thisAttendance?.duration, day)
                  //   : 0.0,

                  percentage:
                    thisAttendance?.isAbsentApproved ||
                    thisAttendance?.isLateApproved ||
                    thisAttendance?.isEarlyApproved
                      ? "100"
                      : thisAttendance?.duration
                      ? `${
                          calculate_salary_percent(
                            thisAttendance?.duration,
                            moment(thisAttendance?.checkoutTime).day()
                          ) >= 100
                            ? calculate_salary_percent(
                                thisAttendance?.duration,
                                moment(thisAttendance?.checkoutTime).day()
                              )
                            : "100"
                        }`
                      : "0 ",

                  isLateApproved: thisAttendance?.isLateApproved,
                  isEarlyApproved: thisAttendance?.isEarlyApproved,
                  isAbsentApproved: thisAttendance?.isAbsentApproved,
                  AbsentApproveComment: thisAttendance?.AbsentApproveComment,
                  EarlyApproveComment: thisAttendance?.EarlyApproveComment,
                  lateApproveComment: thisAttendance?.lateApproveComment,
                };
              }
            }
          });

          details = dataDate.filter((item) => item !== undefined)[0];
        }

        let isAfter = moment(date, "DD-MM-YYYY").isAfter(moment().format());
        console.log(details, "detailsdetails");
        return {
          date,
          day,
          attendance: details,
          percentage: `${
            details?.percentage
              ? `${details?.percentage} %`
              : isAfter
              ? "--"
              : "0%"
          }`,
          status: isWeekend
            ? "Weekend"
            : details
            ? "Present"
            : isAfter
            ? "--"
            : "Absent",
          isLateApproved: details?.isLateApproved,
          isEarlyApproved: details?.isEarlyApproved,
          isAbsentApproved: details?.isAbsentApproved,
          AbsentApproveComment: details?.AbsentApproveComment,
          EarlyApproveComment: details?.EarlyApproveComment,
          lateApproveComment: details?.lateApproveComment,
        };
      });

      //monthly perecent dekhna here
      // let workingPercent = (thisMonthTotalWorkingHours / noOfBusinessHours)
      let workingPercent = (
        (thisMonthTotalWorkingHours / noOfBusinessHours) *
        100
      ).toFixed(2);

      setThisMonthTotalWorkingPercentage(workingPercent);
      setDataToShow(temp);
    }
  }, [data]);

  const onSave = (item, index) => {
    let payload = {
      date: moment(item?.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      _id: id,
    };

    if (editedData?.checkIn) {
      let hours = new Date(editedData?.checkIn).getHours();
      let mins = new Date(editedData?.checkIn).getMinutes();
      payload.checkIn = {
        hours,
        mins,
      };
    }

    if (editedData?.checkOut) {
      let hours = new Date(editedData?.checkOut).getHours();
      let mins = new Date(editedData?.checkOut).getMinutes();
      payload.checkOut = {
        hours,
        mins,
      };
    }

    let data = {
      ...(item?.attendance?._id
        ? { id: item?.attendance?._id, type: "update" }
        : { type: "create" }),
      payload,
    };

    update(data);
  };

  const onSaveHandler = () => {
    update(approvedData);
  };
  const [editIndex, setEditIndex] = useState();
  const [editedData, setEditedData] = useState();

  const toggle = (item, index) => {
    setEditIndex(index);
    setEditedData({ checkIn: item?.checkInTime, checkOut: item?.checkoutTime });
  };

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-9 main-heading-1">
                  <h2>
                    <a href="/admin/attendance" className="btn back">
                      <FaArrowLeft />
                    </a>
                    Salesman's Monthly Attendance
                  </h2>
                </div>
                {/* <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} /> */}

                <div className="col-3 d-flex align-items-center mt-1">
                  <Dropdown>
                    <Dropdown.Toggle className="text-capitalize m-0">
                      {month > 0 ? getMonths()[month - 1] : getMonths()[11]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      {getMonths().map((item, index) => (
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => setMonth(index + 1)}
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="row mt-4">
                <div className="texxtwrp">
                  <div className="d-flex flex-row row">
                    <div className="col-4">
                      <h4>Salesman Name</h4>
                      <p>{details?.fullName}</p>
                    </div>
                    <div className="col-4">
                      <h4>Salesman Email</h4>
                      <p>{details?.email}</p>
                    </div>
                    <div className="col-4">
                      <h4>Salesman Phone</h4>
                      <p>{details?.phoneNumber}</p>
                    </div>
                    <div className="col-4">
                      <h4>Monthly Working Percentage</h4>
                      <p>{thisMonthTotalWorkingPercentage} %</p>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                headings={[
                  "S. No",
                  "Date",
                  "Day",
                  "Time In",
                  "Time out",
                  "Working %",
                  "Status",
                  "Is Late Appoved",
                  "Is Early Appoved",
                  "Is Absent Appoved",
                  "Action",
                ]}
                data={dataToShow}
                loading={isFetching}
                length={dataToShow?.length}
              >
                {dataToShow?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.date}</td>
                    <td>{DAYS[item?.day]}</td>
                    {/* <td>{item?.attendance?.checkInTime || "--"}</td> */}
                    <td>
                      {editIndex === index ? (
                        <input
                          type="time"
                          value={moment(editedData?.checkIn).format("HH:mm")}
                          onChange={(e) => {
                            let time = e.target.value;
                            let date = new Date();
                            date.setHours(time.substring(0, 2));
                            date.setMinutes(time.substring(3, 5));
                            setEditedData((val) => ({ ...val, checkIn: date }));
                          }}
                        />
                      ) : item?.attendance?.checkInTime ? (
                        moment(item?.attendance?.checkInTime).format("hh:mm a")
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="time"
                          value={moment(editedData?.checkOut).format("HH:mm")}
                          onChange={(e) => {
                            let time = e.target.value;
                            let date = new Date();
                            date.setHours(time.substring(0, 2));
                            date.setMinutes(time.substring(3, 5));
                            setEditedData((val) => ({
                              ...val,
                              checkOut: date,
                            }));
                          }}
                        />
                      ) : item?.attendance?.checkoutTime ? (
                        moment(item?.attendance?.checkoutTime).format("hh:mm a")
                      ) : (
                        "--"
                      )}
                    </td>
                    {/* <td>{item?.attendance?.checkoutTime || "--"}</td> */}
                    <td>{item?.percentage}</td>
                    <td
                      className={`text-capitalize ${
                        item?.status === "Present"
                          ? "text-success"
                          : item?.status === "Absent"
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      {item?.status}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (item?.status === "Present" || item?.isLateApproved) {
                            selectForApprove(item, "late", true);
                          }
                        }}
                        className={`${
                          item?.isLateApproved
                            ? "bg-warning text-dark"
                            : "text-light bg-secondary"
                        } rounded-3`}
                      >
                        {item?.isLateApproved ? "Yes" : "No"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (item?.status === "Present" || item?.isEarlyApproved) {
                            selectForApprove(item, "early", true);
                          }
                        }}
                        className={`${
                          item?.isEarlyApproved
                            ? "bg-warning text-dark"
                            : "text-light bg-secondary"
                        } rounded-3`}
                        disabled={
                          item?.status === "Absent" || item?.isAbsentApproved
                        }
                      >
                        {item?.isEarlyApproved ? "Yes" : "No"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (
                            (item?.status !== "Weekend" &&
                              item?.status === "Absent") ||
                            item?.isAbsentApproved
                          ) {
                            selectForApprove(item, "absent", true);
                          }
                        }}
                        className={`${
                          item?.isAbsentApproved
                            ? "bg-warning text-dark"
                            : "text-light bg-secondary"
                        } rounded-3`}
                      >
                        {item?.isAbsentApproved ? "Yes" : "No"}
                      </button>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        {editIndex === index ? (
                          <p
                            onClick={() => onSave(item, index)}
                            className="text-success text-decoration-underline cursor-pointer m-0 mx-2"
                          >
                            Save
                          </p>
                        ) : item?.status !== "Weekend" ? (
                          <p
                            onClick={() => toggle(item?.attendance, index)}
                            className="text-primary text-decoration-underline cursor-pointer m-0 mx-2"
                          >
                            Edit
                          </p>
                        ) : null}
                        {item?.status === "Present" && (
                          <p
                            onClick={() => {
                              selectForApprove(item, "late", false);
                            }}
                            className="text-success ml-2 text-decoration-underline cursor-pointer m-0"
                          >
                            Late Approve
                          </p>
                        )}
                        {item?.status === "Present" && (
                          <p
                            onClick={() => {
                              selectForApprove(item, "early", false);
                            }}
                            className="text-success ml-2 text-decoration-underline cursor-pointer m-0"
                          >
                            Early Approve
                          </p>
                        )}
                        {item?.status === "Absent" && (
                          <p
                            onClick={() => {
                              selectForApprove(item, "absent", false);
                            }}
                            className="text-danger ml-2 text-decoration-underline cursor-pointer m-0"
                          >
                            Absent Approve
                          </p>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </Table>
              {/* {
                                data?.data?.length > 0 &&
                                <Pagination
                                    length={data?.data?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            } */}
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-capitalize">
                {approvedData?.title} Attendance
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={`Approve ${approvedData?.title}`}
                    className="ml-5 fs-4 mb-4 text-capitalize"
                    checked={approvedData.isApproved}
                    onChange={(e) =>
                      setApprovedData({
                        ...approvedData,
                        isApproved: e.target.checked,
                      })
                    }
                  />
                </Form>
                <label htmlFor="comment">Comment for Approve</label>
                <input
                  type="text"
                  id="comment"
                  className="form-control w-auto w-100"
                  value={approvedData.comment}
                  onChange={(e) =>
                    setApprovedData({
                      ...approvedData,
                      comment: e.target.value,
                    })
                  }
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={onSaveHandler}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default AttendanceDetail;
