import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useChangeLeaveStatusMutation, useGetLeaveByIdQuery } from "../../../redux/apis/leaves";
import { format_date } from "../../../utils/helper";
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from "react-toastify";

const LeaveDetails = () => {

    const { user } = useSelector(state => state.userSlice)
    const { settings } = useSelector(State => State.generalSlice)

    const leavesStatus = settings?.leavesStatus?.filter(item => item !== "notResponded")?.map(item => ({ label: item, value: item }))

    const { id } = useParams()

    const { data, isLoading, refetch } = useGetLeaveByIdQuery(id)
    const [updateStatus, { isSuccess }] = useChangeLeaveStatusMutation()

    if (data) {
        var details = data?.data
    }

    const onChangeStatus = (status) => {

        let payload = {
            id,
            status
        }

        updateStatus(payload)

    }

    useEffect(() => {
        if (isSuccess) {
            toast.success("Status Successfully Updated")
            refetch()
        }
    }, [isSuccess])

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="headd1">
                            <h2 style={{ color: '#000', padding: '36px 9px 20px', fontWeight: 700, lineHeight: '40px', fontSize: '30px' }}> <Link to={`/${user?.role}/leaves`} className="btn back"><FaArrowLeft /></Link>Leave Details</h2>
                            {/* <img src={rectangle} /> */}
                        </div>
                        {
                            isLoading ? <Spinner /> :
                                <div className="col-md-12">
                                    <div className="texxtwrp">
                                        <div className="row mb-4">
                                            <div className="col-3" />
                                            <div className="col-3" />
                                            <div className="col-3" />
                                            <div className="col-3">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="text-capitalize m-0">
                                                        {leavesStatus?.find(item => item.value === details?.status)?.label}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="w-100">
                                                        {
                                                            leavesStatus?.map(itemm => (
                                                                <Dropdown.Item className="text-capitalize" onClick={() => onChangeStatus(itemm.value)} >{itemm?.label}</Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row row">
                                            <div className="col-4">
                                                <h4>From Date</h4>
                                                <p>{format_date(details?.from)}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>To Date</h4>
                                                <p>{format_date(details?.to)}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Type</h4>
                                                <p className="text-capitalize">{details?.type}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row row">
                                            <div className="col-4">
                                                <h4>Salesman Name</h4>
                                                <p>{details?.user?.fullName}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Salesman Email</h4>
                                                <p>{details?.user?.email}</p>
                                            </div>
                                        </div>
                                        <h4>Description</h4>
                                        <p>{details?.reason || "-"}</p>

                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div></div>
    );
}

export default LeaveDetails;