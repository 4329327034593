import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import { useGetLeavesRequestsQuery } from "../../../redux/apis/leaves";
import { useUpdateUserMutation } from "../../../redux/apis/user";
import { format_date } from "../../../utils/helper";

const Leaves = () => {

    const navigate = useNavigate()

    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()
    const { data, refetch, isLoading } = useGetLeavesRequestsQuery({ page, rowsPerPage, search, status: JSON.stringify(['approved', 'rejected']) })
    const [updateUser, { isSuccess }] = useUpdateUserMutation()

    useEffect(() => {
        refetch()
    }, [isLoading, isSuccess])

    const onPageChange = page => setPage(page)

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-sm-6 col-6 main-heading-1">
                                    <h2>Leaves</h2>
                                </div>
                                {/* <div className="col-sm-6 col-6 m-b-20" >
                                    <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} />
                                </div> */}
                                <div className="col-sm-6 col-9 m-b-20" >
                                    <a href="/admin/leaves/requests" className="btn btn btn-primary btn-rounded float-right oder text-white px-2">Leave Requests</a>
                                    <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <Table
                                headings={['S. No', 'Salesman Name', "From Date", "To Date", "Status", "View"]}
                                data={data}
                                loading={isLoading}
                                length={data?.data?.length}
                            >

                                {
                                    data?.data?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{item?.user?.fullName}</td>
                                            <td>{format_date(item?.from)}</td>
                                            <td>{format_date(item?.to)}</td>
                                            <td className={`text-capitalize ${item?.status === "pending" ? "text-warning" : item?.status === "rejected" ? "text-danger" : "text-success"}`} >{item?.status}</td>
                                            <td onClick={() => navigate(`/admin/leaves/detail/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">View</td>
                                        </tr>
                                    ))
                                }
                            </Table>
                            {
                                data?.data?.length > 0 &&
                                <Pagination
                                    length={data?.data?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div></div>

    );
}
export default Leaves;