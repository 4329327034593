import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  useGetAttendanceQuery,
  useUpdateAttendanceMutation,
} from "../../../redux/apis/attendance";
import { useLogoutMutation } from "../../../redux/apis/auth";
import { WORKING_HOURS } from "../../../utils/constants";
import { calculate_salary_percent } from "../../../utils/helper";
import { Form } from "react-bootstrap";

const Attendance = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editIndex, setEditIndex] = useState();
  const [editedData, setEditedData] = useState();

  const [approvedData, setApprovedData] = useState({
    isApproved: false,
    comment: "",
    id: "",
    type: "",
    title: "",
    salesmanId: "",
    isEdit: "",
    date: "",
  });

  let selectForApprove = (item, title, isEdit) => {
    console.log(item, "6666666666");
    handleShow();
    setApprovedData({
      type: "approve",
      isApproved:
        title === "absent"
          ? item?.isAbsentApproved
          : title === "early"
          ? item?.isEarlyApproved
          : title === "late"
          ? item?.isLateApproved
          : false,
      comment:
        title === "absent"
          ? item?.AbsentApproveComment
          : title === "early"
          ? item?.EarlyApproveComment
          : title === "late"
          ? item?.lateApproveComment
          : "-",

      id: item?.salesman?._id,
      date: item?.date ? item?.date : moment().format("MM-DD-yyyy"),
      title: title,
    });
  };

  console.log(approvedData, "approvedData");

  const toggle = (item, index) => {
    setEditIndex(index);
    setEditedData({ checkIn: item?.checkInTime, checkOut: item?.checkoutTime });
  };
  const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] =
    useTableControls();
  const { data, refetch, isLoading } = useGetAttendanceQuery({
    page,
    rowsPerPage,
    search,
  });
  const [logout, { isSuccess }] = useLogoutMutation();

  const [update, { data: updateRes, isSuccess: updateSuccess }] =
    useUpdateAttendanceMutation();

  useEffect(() => {
    if (updateSuccess) {
      toast.success(updateRes?.message);
      refetch();
      setEditIndex();
      setEditedData();
      setShow(false);
    }
  }, [updateSuccess]);

  useEffect(() => {
    refetch();
    if (isSuccess) {
      toast.success("Salesman marked as checkout");
    }
  }, [isLoading, isSuccess]);

  const onPageChange = (page) => setPage(page);

  const onLogout = (id) => {
    logout({ salesmanId: id });
  };

  const onSave = () => {
    update(approvedData);
  };

  // console.log(data, "data");

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-sm-6 col-6 main-heading-1">
                  <h2>Attendance</h2>
                </div>
                <div className="col-sm-6 col-6 m-b-20">
                  <input
                    type="search"
                    className="search-1"
                    placeholder="Search Here..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <Table
                // headings={['S. No', 'Salesman Name', "Date", "Time In", "Time out", "Status"]}
                headings={[
                  "S. No",
                  "Salesman Name",
                  "Date",
                  "Time In",
                  "Time out",
                  "Working %",
                  "Status",
                  "Is Late Appoved",
                  "Is Early Appoved",
                  "Is Absent Appoved",
                  "Action",
                  // "Comments",
                ]}
                data={data}
                loading={isLoading}
                length={data?.data?.length}
              >
                {data?.data?.map((item, index) => (
                  <tr>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.salesman?.fullName}</td>
                    <td>
                      {moment(item?.attendace?.checkInTime).format(
                        "DD-MM-YYYY"
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="time"
                          value={moment(editedData?.checkIn).format("HH:mm")}
                          onChange={(e) => {
                            let time = e.target.value;
                            let date = new Date();
                            date.setHours(time.substring(0, 2));
                            date.setMinutes(time.substring(3, 5));
                            setEditedData((val) => ({ ...val, checkIn: date }));
                          }}
                        />
                      ) : item?.attendace?.checkInTime ? (
                        moment(item?.attendace?.checkInTime).format("hh:mm a")
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="time"
                          value={moment(editedData?.checkOut).format("HH:mm")}
                          onChange={(e) => {
                            let time = e.target.value;
                            let date = new Date();
                            date.setHours(time.substring(0, 2));
                            date.setMinutes(time.substring(3, 5));
                            setEditedData((val) => ({
                              ...val,
                              checkOut: date,
                            }));
                          }}
                        />
                      ) : item?.attendace?.checkoutTime ? (
                        moment(item?.attendace?.checkoutTime).format("hh:mm a")
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {item?.isAbsentApproved ||
                      item?.isLateApproved ||
                      item?.isEarlyApproved
                        ? "100%"
                        : item?.duration
                        ? `${
                            calculate_salary_percent(
                              item?.duration,
                              moment(item?.attendace?.checkoutTime).day()
                            ) >= 100
                              ? calculate_salary_percent(
                                  item?.duration,
                                  moment(item?.attendace?.checkoutTime).day()
                                )
                              : "100"
                          } %`
                        : "0 %"}
                    </td>
                    {/* <td>{item?.duration ? `${((item?.duration / WORKING_HOURS) * 100).toFixed(2)} %` : "0 %"}</td> */}
                    <td
                      className={`text-capitalize ${
                        item?.status === "absent"
                          ? "text-danger"
                          : "text-success"
                      }`}
                    >
                      {item?.status}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (
                            item?.status === "active" ||
                            item?.isLateApproved
                          ) {
                            selectForApprove(item, "late", true);
                          }
                        }}
                        className={`${
                          item?.isLateApproved
                            ? "bg-warning text-dark"
                            : "text-light bg-secondary"
                        } rounded-3`}
                      >
                        {item?.isLateApproved ? "Yes" : "No"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (
                            item?.status === "active" ||
                            item?.isEarlyApproved
                          ) {
                            selectForApprove(item, "early", true);
                          }
                        }}
                        className={`${
                          item?.isEarlyApproved
                            ? "bg-warning text-dark"
                            : "text-light bg-secondary"
                        } rounded-3`}
                      >
                        {item?.isEarlyApproved ? "Yes" : "No"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (
                            item?.status === "Absent" ||
                            item?.status === "absent" ||
                            item?.isAbsentApproved
                          ) {
                            selectForApprove(item, "absent", true);
                          }
                        }}
                        className={`${
                          item?.isAbsentApproved
                            ? "bg-warning text-dark"
                            : "text-light bg-secondary"
                        } rounded-3`}
                      >
                        {item?.isAbsentApproved ? "Yes" : "No"}
                      </button>
                    </td>

                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        {editIndex !== index && (
                          <>
                            <p
                              className={`${
                                !item?.attendace?.checkoutTime &&
                                item?.attendace?.checkInTime
                                  ? "text-black"
                                  : "text-disabled"
                              } text-decoration-underline cursor-pointer my-0 mx-1`}
                              onClick={() => {
                                if (
                                  !item?.attendace?.checkoutTime &&
                                  item?.attendace?.checkInTime
                                ) {
                                  onLogout(item?.salesman?._id);
                                }
                              }}
                            >
                              {"Mark Checkout"}
                            </p>
                            <p
                              onClick={() =>
                                navigate(
                                  `/admin/attendance/detail/${item?.salesman?._id}`
                                )
                              }
                              className="text-primary ml-2 text-decoration-underline cursor-pointer m-0"
                            >
                              View
                            </p>

                            {!item?.isAbsentApproved && item?._id && (
                              <p
                                onClick={() => {
                                  selectForApprove(item, "late", false);
                                }}
                                className="text-success ml-2 text-decoration-underline cursor-pointer m-0"
                              >
                                Late Approve
                              </p>
                            )}
                            {!item?.isAbsentApproved && item?._id && (
                              <p
                                onClick={() => {
                                  selectForApprove(item, "early", false);
                                }}
                                className="text-success ml-2 text-decoration-underline cursor-pointer m-0"
                              >
                                Early Approve
                              </p>
                            )}
                            {item?.status === "absent" && (
                              <p
                                onClick={() => {
                                  selectForApprove(item, "absent", false);
                                }}
                                className="text-danger ml-2 text-decoration-underline cursor-pointer m-0"
                              >
                                Absent Approve
                              </p>
                            )}
                          </>
                        )}
                        {/* {
                                                        editIndex === index ?
                                                            <p onClick={() => onSave(item?.attendace, index)} className="text-success text-decoration-underline cursor-pointer m-0 mx-2" >Save</p> :
                                                            item?.attendace ?
                                                                <p onClick={() => toggle(item?.attendace, index)} className="text-primary text-decoration-underline cursor-pointer m-0 mx-2" >Edit</p> : null
                                                    } */}
                      </div>
                    </td>
                    {/* <td className={`text-capitalize `}>
                      {item?.approveComment}
                    </td> */}
                  </tr>
                ))}
              </Table>
              {data?.data?.length > 0 && (
                <Pagination
                  length={data?.data?.length}
                  totalDocs={data?.total}
                  onPageChange={onPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">
            {approvedData?.title} Attendance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label={`Approve ${approvedData?.title}`}
                className="ml-5 fs-4 mb-4 text-capitalize"
                checked={approvedData.isApproved}
                onChange={(e) =>
                  setApprovedData({
                    ...approvedData,
                    isApproved: e.target.checked,
                  })
                }
              />
            </Form>
            <label htmlFor="comment">Comment for Approve</label>
            <input
              type="text"
              id="comment"
              className="form-control w-auto w-100"
              value={approvedData.comment}
              onChange={(e) =>
                setApprovedData({ ...approvedData, comment: e.target.value })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Attendance;
