import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  useGetCommissionQuery,
  useEditCommissionMutation,
  useCreatedCommissionMutation,
} from "../../../redux/apis/commission";
import { useLogoutMutation } from "../../../redux/apis/auth";
import { WORKING_HOURS } from "../../../utils/constants";
import { calculate_salary_percent, format_date } from "../../../utils/helper";
import { Form } from "react-bootstrap";

const Commission = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [validationError, setValidationError] = useState("");

  const handleClose = () => {
    setShow(false);
    setValidationError("");
  };

  const handleShow = () => {
    setShow(true);
    setIsEdit(false);
    setCommissionData({
      id: "",
      type: "",
      commission: "",
      expiryDate: moment().format("YYYY-MM-DD")
    });
    setValidationError("");
  };

  const [editIndex, setEditIndex] = useState();
  const [editedData, setEditedData] = useState();

  const toggle = (item, index) => {
    setEditIndex(index);
    setEditedData({ checkIn: item?.checkInTime, checkOut: item?.checkoutTime });
  };

  const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] =
    useTableControls();
  const { data, refetch, isLoading } = useGetCommissionQuery({
    page,
    rowsPerPage,
    search,
  });

  const [logout, { isSuccess }] = useLogoutMutation();

  const [update, { data: updateRes, isSuccess: updateSuccess }] =
    useEditCommissionMutation();
  const [createCommission, { data: createResCommission, isSuccess: createSuccessCommission }] =
    useCreatedCommissionMutation();

  useEffect(() => {
    if (updateSuccess) {
      // toast.success(updateRes?.message);
      refetch();
      setEditIndex();
      setEditedData();
      setShow(false);
    }
  }, [updateSuccess,createSuccessCommission]);

  useEffect(() => {
    if (createSuccessCommission) {
      toast.success(createResCommission?.message);
      refetch();
      setShow(false);
    }
  }, [createSuccessCommission]);

  useEffect(() => {
    refetch();
    if (isSuccess) {
      toast.success("Salesman marked as checkout");
    }
  }, [isLoading, isSuccess]);

  const onPageChange = (page) => setPage(page);

  const onLogout = (id) => {
    logout({ salesmanId: id });
  };

  const [commissionData, setCommissionData] = useState({
    id: "",
    type: "",
    commission: "",
    expiryDate: moment().format("YYYY-MM-DD")
  });

  const selectData = (item) => {
    setShow(true);
    setIsEdit(true);
    setCommissionData({
      type: item?.type,
      id: item?._id,
      commission: item?.commission,
      expiryDate: moment(item?.expiryDate).format("YYYY-MM-DD")
    });
    setValidationError("");
  };

  const validateForm = () => {
    if (!commissionData.type || !commissionData.commission || !commissionData.expiryDate) {
      setValidationError("All fields are required.");
      return false;
    }
    if (commissionData.commission < 0 || commissionData.commission > 100) {
      setValidationError("Commission must be between 0 and 100.");
      return false;
    }
    setValidationError("");
    return true;
  };

  const onSave = () => {
    if (!validateForm()) {
      return;
    }
    if (isEdit) {
      update(commissionData);
    } else {
      const { id, ...commissionDataToCreate } = commissionData;
      createCommission(commissionDataToCreate);
    }
  };

  const todayDate = moment().format("YYYY-MM-DD");

  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-sm-8 col-6 main-heading-1">
                  <h2>Commission</h2>
                </div>
                <div className="col-sm-4 col-6 text-right">
                  <Button variant="primary" onClick={handleShow}>
                    Add Commission
                  </Button>
                </div>
              </div>
              <Table
                headings={["S. No", "Type", "Commission","Expiry Date", "Action"]}
                data={data}
                loading={isLoading}
                length={data?.data?.length}
              >
                {data?.data?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.type}</td>
                    <td>{item?.commission}%</td>
                    <td>{format_date(item?.expiryDate)}</td>
                    <td onClick={() => selectData(item)}>
                      <p className="text-primary ml-2 text-decoration-underline cursor-pointer m-0">
                        EDIT
                      </p>
                    </td>
                  </tr>
                ))}
              </Table>
              {data?.data?.length > 0 && (
                <Pagination
                  length={data?.data?.length}
                  totalDocs={data?.total}
                  onPageChange={onPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">{isEdit ? "Edit" : "Add"} Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {validationError && <p className="text-danger">{validationError}</p>}
            <label htmlFor="type">Type</label>
            <select
              id="type"
              className="form-control w-auto w-100"
              value={commissionData.type}
              onChange={(e) =>
                setCommissionData({ ...commissionData, type: e.target.value })
              }
              disabled={isEdit}
            >
              <option value="">Select Type</option>
              <option value="A">A</option>
              <option value="B">B</option>
            </select>
            <label htmlFor="commission">Commission</label>
            <input
              type="number"
              id="commission"
              className="form-control w-auto w-100"
              value={commissionData.commission}
              onChange={(e) =>
                setCommissionData({
                  ...commissionData,
                  commission: e.target.value,
                })
              }
              min="0"
              max="100"
            />
            <label htmlFor="expiryDate">Expiry Date</label>
            <input
              type="date"
              id="expiryDate"
              className="form-control w-auto w-100"
              value={commissionData.expiryDate}
              onChange={(e) =>
                setCommissionData({
                  ...commissionData,
                  expiryDate: e.target.value,
                })
              }
              min={todayDate}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Commission;
