import { useEffect } from 'react';
import { useGetSettingsQuery, useLazyGetNotificationQuery } from '../redux/apis/general';
import Router from '../routers';
import './App.css';
import { useSelector } from 'react-redux';
import { handleForegroundMessage, requestNotificationPermission } from '../utils/notifications';

function App() {

  const { loggedIn } = useSelector(state => state.userSlice)
  const { settings } = useSelector(State => State.generalSlice)

  const { data, refetch } = useGetSettingsQuery()
  const [getNotification] = useLazyGetNotificationQuery()

  useEffect(() => {
    // requestNotificationPermission()
    // handleForegroundMessage()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getNotification()
    }
  }, [loggedIn])

  useEffect(() => {
    if (!settings) {
      refetch()
    }
  }, [settings])

  return (
    <Router />
    // <Approval/>      
    // <ApprProfile/>
    // <EditProfile/>
    // <EditProfile2/>
    // <Dashboard/>
    // <MyOrder/>
    // <Notification/>
    // <OrderCreation/>
    // <OrderManagement/>
    // <Salary/>
    // <Salesman/>
    // <SalesManage/>
    // <SpecificSalary/>
  );
}

export default App;
