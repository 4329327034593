import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'image/'

export const UploadApiService = createApi({
    reducerPath: 'uploadApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        uploadProductImage: builder.mutation({
            query: (payload) => ({
                url: 'upload-product-image',
                method: 'POST',
                body: payload
            })
        }),
        uploadBannerImage: builder.mutation({
            query: (payload) => ({
                url: 'upload-homebanner-image',
                method: 'POST',
                body: payload
            })
        })
        ,
        uploadProfile: builder.mutation({
            query: (payload) => ({
                url: 'upload',
                method: 'POST',
                body: payload
            })
        })
    })
})

export const { useUploadBannerImageMutation, useUploadProductImageMutation, useUploadProfileMutation } = UploadApiService