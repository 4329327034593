import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import media from "../assests/Media.svg";
import login from "../assests/login.png";
import { useSetPasswordMutation } from "../redux/apis/auth";

const ChangePassword = () => {

  const { state } = useLocation()
  const navigate = useNavigate()

  const [pass, setPass] = useState('')
  const [repass, setRepass] = useState('')

  const [resetPass, { isSuccess }] = useSetPasswordMutation()

  useEffect(() => {
    if (isSuccess) {
      navigate('/login')
      toast.success("Password has been resetted successfully")
    }
  }, [isSuccess])

  const onClickUpdate = e => {
    e.preventDefault()
    resetPass({ email: state?.email, password: pass })
  }

  return (
    <div>
      <section className="does_work_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft bgg-cl">
              <div className="does_work_boxes">
                <div className="form-logo">
                  {/* <img src={media} /> */}
                </div>
                <div className="does_work_box1 ">
                  <h3>Password Recovery</h3>
                  <p>Enter New Password</p>
                  <div className="contact_form_all">
                    <input type="hidden" name="_token" defaultValue="iCCu8L3Cd3S6umnTMIlEMZGMKGGRsgqQzASO63GR" />              <div className>
                      <div className="col-sm-12">
                        <div className="md-form">
                          <h6>New Password</h6>
                          <input type="Password" name="Password" placeholder="Enter New Password" required="required" className="form-control" value={pass} onChange={e => setPass(e.target.value)} />
                          {/* <i class="fa-solid fa-eye" id="eye"></i> */}
                        </div>
                        <div className="md-form">
                          <h6>Confirm Password</h6>
                          <input type="Password" name="Password" placeholder="Enter Confirm Password" required="required" className="form-control" value={repass} onChange={e => setRepass(e.target.value)} />
                          {/* <i class="fa-solid fa-eye" id="eye"></i> */}
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="page_name" defaultValue="Postcard" />
                    <div className="col-md-12 login">
                      <button onClick={onClickUpdate} className="btn blue_btn">Update</button>
                    </div>
                    <div className="col-sm-12">
                      <div className="col-md-12">
                        <div className="uuull">
                          <ul>
                            <li>Back to</li>
                            <li><a href="javascript:;" className="login-2">Login</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="frst-form-img">
                <img src={login} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
}
export default ChangePassword;