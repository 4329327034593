import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import media from "../assests/Media.svg";
import loginImage from "../assests/login.png";
import { useLoginMutation } from "../redux/apis/auth";
import './App.css';
import Button from "./Button";
import InputField from "./InputField";

const Login = () => {

  const navigate = useNavigate()
  const { control, handleSubmit } = useForm()

  const [login, { data, isLoading, isSuccess }] = useLoginMutation()

  const onSubmit = data => {
    login(data)
  }

  useEffect(() => {
    if (isSuccess) {
      navigate(`/${data?.data?.role}/`)
    }
  }, [isSuccess])

  return (
    <div>
      <section className="does_work_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft bgg-cl">
              <div className="does_work_boxes">
                <div className="form-logo">
                  {/* <img src={media} /> */}
                </div>
                <div className="does_work_box1 ">
                  <h3>LOG IN</h3>
                  <p>Log In To Continue</p>
                  <form className="contact_form_all" action method="POST">
                    <div className>
                      <div className="col-sm-9">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <InputField
                              label={"Email Address"}
                              placeholder="Enter Email Address"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <InputField
                            label={"Password"}
                            type="password"
                            placeholder="Enter Password"
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 chk-bx-st">
                        <input type="checkbox" id="test2" required />
                        <label htmlFor="test2" style={{ color: '#555', fontWeight: 600, fontSize: '12px' }}>Remember me</label><br />
                      </div>
                      <div className="col-md-6 main-for">
                        <a href="/forget-password" className="forgt">Forgot Password?</a>
                      </div>
                    </div>
                    <input type="hidden" name="page_name" defaultValue="Postcard" />
                    <div className="col-md-12 login">
                      <Button text="LOG IN" onClick={handleSubmit(onSubmit)} loading={isLoading} />
                    </div>
                    <div className="col-sm-12">
                      <p id="status" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="frst-form-img">
                <img src={loginImage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


export default Login;