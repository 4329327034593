import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../../utils/constants";

const baseUrl = BASE_URL + "commission/";

export const CommissionApiService = createApi({
  reducerPath: "commissionApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      let { token } = getState().userSlice;
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createdCommission: builder.mutation({
      query: (payload) => {
        return {
          url: `create`,
          method: "POST",
          body: payload,
        };
      },
    }),
    editCommission: builder.mutation({
      query: (payload) => {
        return {
          url: `edit/${payload.id}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    getSalesmanCommission: builder.query({
      query: (payload) => {
        return {
          url: `salesman-commission`,
          method: "Get",
        };
      },
      keepUnusedDataFor: 0,
    }),
  
    getCommission: builder.query({
      query: (args) => {
        return "all";
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useEditCommissionMutation, useGetCommissionQuery ,useCreatedCommissionMutation , useGetSalesmanCommissionQuery } =
  CommissionApiService;
