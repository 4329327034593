import { Spinner } from "react-bootstrap"

const Button = ({ text = "", onClick = () => { }, type = "submit", loading = false, htmlFor }) => {
    return (
        loading ?
            <div className="d-flex my-4 align-items-center"> < Spinner color="#214391" /> </div > :
            <button disabled={loading} htmlFor={htmlFor} type={type} className="btn blue_btn intiv-btn align-items-center justify-content-center" onClick={onClick}>{text}</button>

    )
}

export default Button