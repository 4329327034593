import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import popup from "../../../assests/popup.png";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import { useGetBudgetsQuery } from "../../../redux/apis/budget";
import { useUpdateUserMutation } from "../../../redux/apis/user";
import { CURRENCY, amountFormatter, getMonths } from "../../../utils/helper";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaBasketShopping,
  FaCalendar,
  FaCheck,
  FaCross,
  FaUntappd,
  FaX,
} from "react-icons/fa6";
import { FaCrosshairs, FaDollarSign } from "react-icons/fa";

const Budget = () => {
  const navigate = useNavigate();

  const [from, setFrom] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [month, setMonth] = useState(moment().month() + 1);

  const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] =
    useTableControls();
  const { data, refetch, isLoading } = useGetBudgetsQuery({ from, to });
  const [updateUser, { isSuccess }] = useUpdateUserMutation();

  useEffect(() => {
    refetch();
  }, [isLoading, isSuccess]);

  const onPageChange = (page) => setPage(page);

  const onStatusChange = (item) => {
    updateUser({ id: item?._id, active: !item?.active });
  };

  const onMonthChange = (m) => {
    setMonth(m);

    let year = new Date().getFullYear();

    let f = moment(`${year}-${m}`, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    let e = moment(`${year}-${m}`, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");

    setFrom(f);
    setTo(e);
  };

  console.log(data?.data?.allSalesmanStats, "data?.data");
  let stats = data?.data?.allSalesmanStats || {};

  const [totalOrderValue, setTotalOrderValue] = useState(0);
  useEffect(() => {
    setTotalOrderValue(0);
    const sumOrderValues = data?.data?.budget?.reduce(
      (total, product) => total + product.amount,
      0
    );
    setTotalOrderValue(sumOrderValues);
  }, [data?.data]);
  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-6 main-heading-1">
                  <h2>Budget Management</h2>
                </div>
                <div className="col-6 w-100 d-flex align-items-center justify-content-between">
                  <input
                    type="search"
                    className="search123"
                    placeholder="Search Here..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Dropdown>
                    <Dropdown.Toggle className="text-capitalize m-0">
                      {getMonths()[month - 1]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      {getMonths()?.map((itemm, index) => (
                        <Dropdown.Item
                          className="text-capitalize"
                          value={index + 1}
                          onClick={() => onMonthChange(index + 1)}
                        >
                          {itemm}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <a
                    href="/admin/budget/add"
                    className="btn btn btn-primary btn-rounded oder text-white px-2 mt-0"
                  >
                    Add Budget
                  </a>
                </div>
                {/* <div className="col-2 d-flex align-items-center justify-content-center" >
                                </div> */}
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaCrosshairs />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>{stats?.totalAssignedBudget} {CURRENCY} </h3>
                          <span className="widget-title2">Target Amount</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaArrowDown />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.remainingConfirmedTarget} {CURRENCY}
                          </h3>
                          <span className="widget-title2">
                            Remaining Confirmed Target
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaBasketShopping />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.remainingUnconfirmedTarget} {CURRENCY}
                          </h3>
                          <span className="widget-title2">
                            Remaining Unconfirmed Target
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaDollarSign />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.totalAmountOfOrder} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                              |{" "}
                              {(
                                (Number(stats?.totalAmountOfOrder) /
                                  Number(stats?.totalAssignedBudget)) *
                                100
                              ).toFixed(2)}{" "}
                              %
                            </span>
                          </h3>
                          <span className="widget-title2">
                            Total Amount of Orders
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaCheck />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.totalAmountOfPaidOrder} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                              |{" "}
                              {(
                                  (Number(stats?.totalAmountOfPaidOrder) /
                                    Number(stats?.totalAssignedBudget)) *
                                  100
                                ).toFixed(2)}{" "}
                              %
                            </span>
                          </h3>
                          <span className="widget-title2">
                            Paid Order Amount
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaX />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.totalAmountOfUnpaidOrder} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                              |{" "}
                              {(
                                  (Number(stats?.totalAmountOfUnpaidOrder) /
                                    Number(stats?.totalAssignedBudget)) *
                                  100
                                ).toFixed(2)}{" "}
                              %
                            </span>
                          </h3>
                          <span className="widget-title2">
                            Unpaid Order Amount
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaCheck />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.totalAmountOfCreditedOrder} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                              |{" "}
                              {(
                                  (Number(stats?.totalAmountOfCreditedOrder) /
                                    Number(stats?.totalAssignedBudget)) *
                                  100
                                ).toFixed(2)}{" "}
                              %
                            </span>
                          </h3>
                          <span className="widget-title2">
                            Credited Order Amount
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaX />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            {stats?.totalAmountOfOverdueOrder} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                              |{" "}
                              {(
                                  (Number(stats?.totalAmountOfOverdueOrder) /
                                    Number(stats?.totalAssignedBudget)) *
                                  100
                                ).toFixed(2)}{" "}
                              %
                            </span>
                          </h3>
                          <span className="widget-title2">
                            Overdue Order Amount
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="dash-widget">
                    <span className="dash-widget-bg2">
                      <FaDollarSign />
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dash-widget-info">
                          <h3>
                            <span>
                         {totalOrderValue == stats?.totalAmountOfCreditedOrder ? "Yes":"No"}</span>
                            {/* {totalOrderValue} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                                | {totalOrderValue}
                            </span> */}
                          </h3>
                          <span className="widget-title2">
                            Budget Target is achieved ?
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                headings={["S. No", "Salesman Name", "Amount", "View"]}
                data={data}
                loading={isLoading}
                length={data?.data?.budget?.length}
              >
                {data?.data?.budget?.map((item, index) => (
                  <tr>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.salesman?.fullName}</td>
                    <td>
                      {amountFormatter(item?.amount)} {CURRENCY}
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/admin/budget/detail/${item?._id}`)
                      }
                      className="text-primary text-decoration-underline cursor-pointer"
                    >
                      View
                    </td>
                  </tr>
                ))}
              </Table>
              {data?.data?.budget?.length > 0 && (
                <Pagination
                  length={data?.data?.length}
                  totalDocs={data?.total}
                  onPageChange={onPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Budget;
