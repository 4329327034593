import React, { useEffect } from "react";
// import popup from "../assests/popup.png"
import { FaSort } from 'react-icons/fa6';
import { FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

import { useSelector } from "react-redux";
import useTableControls from "../../../hooks/useTableControls";
import { useGetCustomersQuery } from "../../../redux/apis/customer";
import { format_date } from "../../../utils/helper";
import Pagination from "../../../components/Table/Pagination";
import Table from "../../../components/Table/index";

const Customers = () => {

    const { user } = useSelector(state => state.userSlice)
    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()

    const { data, refetch, isLoading } = useGetCustomersQuery({ page, rowsPerPage, createdBy: user?._id, search })

    useEffect(() => {
        refetch()
    }, [isLoading])

    const onPageChange = page => setPage(page)

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2>Customer Management</h2>
                                </div>
                                <div className="col-sm-6 col-9 text-right m-b-20">
                                    <input type="search" name className="search-1 search-4" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <Table
                                headings={['S. No', "Org. No", 'Name', 'Email', 'Phone', "Customer Type", "Registration Date"]}
                                data={data}
                                loading={isLoading}
                                length={data?.data?.length}
                            >
                                {
                                    data?.data?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{item?.organisationNumber}</td>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.email || "-"}</td>
                                            <td>{item?.phone || "-"}</td>
                                            <td className={`${item?.newCustomer ? "text-success" : "text-primary"}`}>{item?.newCustomer ? "A" : "B"} Customer</td>
                                            <td>{format_date(item?.createdAt)}</td>
                                            {/* <td className="text-right">
                                                <div className="dropdown dropdown-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><FaEllipsisV /></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><FaTrashAlt /> Delete</a>
                                                    </div>
                                                </div>
                                            </td> */}
                                        </tr>
                                    ))
                                }
                            </Table>
                            {
                                data?.data?.length > 0 &&
                                <Pagination
                                    length={data?.data?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div></div>

    );
}
export default Customers;