import { FaEllipsisV, FaSort, FaTrashAlt } from "react-icons/fa";
import { format_date } from "../../utils/helper";
import { Spinner } from "react-bootstrap";

const Table = ({ headings, data, loading, children, length }) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-border table-striped custom-table datatable mb-0">
                        <thead>
                            <tr>
                                {
                                    headings?.map(item => (
                                        <th>{item}</th>
                                    ))
                                }
                                {/* <th><FaSort /> User Name</th>
                                <th><FaSort /> Email Address</th>
                                <th><FaSort /> Registration Date</th>
                                <th ><FaSort /> Action </th> */}
                            </tr>
                        </thead>
                        {
                            !loading &&
                            <tbody>
                                {children}
                            </tbody>
                        }
                    </table>
                    {
                        loading &&
                        <div className="d-flex w-100 my-3 text-center justify-content-center">
                            <Spinner />
                        </div>
                    }
                    {
                        !loading && length <= 0 && (
                            <div className="d-flex w-100 mt-3 text-center justify-content-center">
                                <h4>No Data Found</h4>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Table;