import { useState } from "react";

const useTableControls = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearchValue] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("all");

  const setSearch = (val) => {
    setPage(1);
    setSearchValue(val);
  };

  return [
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    status,
    setStatus,
    category,
    setCategory,
  ];
};

export default useTableControls;
