import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../redux/apis/user";
import Button from "./Button";
import InputField from "./InputField";

const EditProfile = () => {

  const navigate = useNavigate()

  const { user } = useSelector(state => state.userSlice)
  const { control, handleSubmit, getValues, setValue, formState: { errors }, watch } = useForm({ defaultValues: { fullName: user?.fullName, phoneNumber: user?.phoneNumber } })
  const [editProfile, { isSuccess, isLoading }] = useUpdateUserMutation()

  const onSubmit = data => {
    editProfile({ ...data, id: user._id })
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success("Profile Updated")
      navigate(`/${user?.role}/profile`)
    }
  }, [isSuccess])

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/> */}
        {/* <SideBar/>     */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2><Link to="/apprprofile" className="btn back"><FaArrowLeft /></Link>Edit Profile</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="fullName"
                      control={control}
                      rules={{
                        required: "Full Name is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Full Name"
                          placeholder="Enter Full Name"
                          required
                          error={errors?.fullName?.message}
                          {...field}
                        />
                      )}
                    />
                    {/* <div className="md-form">
                      <h6>First Name</h6>
                      <input type="text" id="name" name="Name" placeholder="Enter First Name" required="required" className="form-control" />
                    </div> */}
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <div className="md-form">
                      <h6>Email Address</h6>
                      <a className="gmaill" style={{ color: 'black' }}>{user?.email}</a>
                    </div>
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "Phone Number is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Phone Number"
                          placeholder="Enter Phone Number"
                          required
                          error={errors?.phoneNumber?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12">
                    <Button text="Update" onClick={handleSubmit(onSubmit)} loading={isLoading} />
                    {/* <input className type="hidden" name="ctry" defaultValue /> */}
                    {/*<input type="hidden" name="pc" value="">*/}
                    {/* <input type="hidden" name="cip" defaultValue />
                    <input type="hidden" name="hiddencapcha" defaultValue />
                    <input type="hidden" id="location" name="locationURL" defaultValue />
                    <input type="hidden" id="formtype" name="formtype" defaultValue={1} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default EditProfile;