import React, { useEffect, useRef, useState } from "react";
import image44 from "../assests/image 44.png";
import placeholder from "../assests/placeholder.jpeg";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useUploadFileMutation } from "../redux/apis/general";
import { useUpdateUserMutation } from "../redux/apis/user";

const ApprProfile = () => {

  const { user } = useSelector(state => state.userSlice)

  const dpRef = useRef(null)

  const [dp, setDp] = useState()

  const [editProfile, { isSuccess: profileUpdated, isLoading }] = useUpdateUserMutation()
  const [upload, { data: fileData, isSuccess }] = useUploadFileMutation()

  const onChangeDp = e => {
    setDp(e.target.files[0])
    let formData = new FormData()
    formData.append("file", e.target.files[0])
    upload(formData)
  }

  useEffect(() => {
    if (isSuccess) {
      let payload = {
        image: fileData.imageUrl,
        id: user?._id
      }

      editProfile(payload)
    }
  }, [isSuccess])

  useEffect(() => {
    if (profileUpdated) {
      toast.success("Profile Picture Updated Successfully")
    }
  }, [profileUpdated])

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
            <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2 style={{ padding: '26px 9px 30px' }}>My Profile</h2>
                </div>
                <div className="col-md-12">
                  <div className="imagee"> 
                  
                    <img src={dp ? URL.createObjectURL(dp) : user?.image ? user?.image : placeholder} onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = placeholder;
                    }} style={{ width: '100px', height: '100px' }} />
                  </div>
                  <div className="img-22">
                    <img src={image44} onClick={() => dpRef?.current?.click()} />
                    <input ref={dpRef} type="file" hidden onChange={onChangeDp} />
                  </div>

                  <div className="prof-nav1">
                    <ul>
                      <li>Email Address</li>
                      <li>Full Name</li>
                      <li>Phone Number</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12">
                </div>
                <div className="col-md-12">
                  <div className="prof-nav2">
                    <ul>
                      <li>{user?.email}</li>
                      <li>{user?.fullName}</li>
                      <li>{user?.phoneNumber}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="prof-btns">
                    <Link className="btnn-1" to={`/${user?.role}/profile/edit`}>Edit Profile</Link>
                    <Link className="btnn-2" to={`/${user?.role}/profile/change-password`}>Change Password</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ApprProfile;