import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft } from 'react-icons/fa6';
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useAddUserMutation, useGetUsersQuery } from "../../../redux/apis/user";
import { getMonths } from "../../../utils/helper";
import moment from "moment";
import { useCreateBudgetMutation } from "../../../redux/apis/budget";

const AddBudget = () => {

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const { data: salesmans } = useGetUsersQuery({ page: 1, rowsPerPage: 1000 })
  const [createBudget, { data, isLoading, isSuccess }] = useCreateBudgetMutation()

  const onSubmit = data => {

    let currentYear = new Date().getFullYear()
    let date = currentYear + data?.month
    let thisMoment = moment(date, "YYYY-MM");

    let to = moment(thisMoment).endOf('month').format("YYYY-MM-DD");
    let from = moment(thisMoment).startOf('month').format("YYYY-MM-DD");

    createBudget({
      from,
      to,
      salesman: data.salesman,
      amount: data?.amount
    })

  }

  useEffect(() => {
    if (isSuccess) {
      toast.success("Budget Successfully Assign to Salesman")
      reset()
    }
  }, [isSuccess])

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2><a href="/admin/budget" className="btn back"><FaArrowLeft /></a>Add Budget</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="month"
                      control={control}
                      rules={{
                        required: "Month is required"
                      }}
                      render={({ field }) => (
                        <div className="md-form">
                          <h6>For the Month of <span className="text-danger">*</span></h6>
                          <select
                            className="form-control"
                            {...field}
                          >
                            {
                              getMonths().map((item, index) => <option value={index + 1}>{item}</option>)
                            }
                          </select>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="salesman"
                      control={control}
                      rules={{
                        required: "Salesman is required"
                      }}
                      render={({ field }) => (
                        <div className="md-form">
                          <h6>Salesman <span className="text-danger">*</span></h6>
                          <select
                            className="form-control"
                            {...field}
                          >
                            {
                              salesmans?.data?.map(item => <option value={item._id}>{item.fullName}</option>)
                            }
                          </select>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: "Target Amount is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label={"Target Amount"}
                          placeholder="Enter Target Amount"
                          error={errors?.amount?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12">
                    <Button
                      loading={isLoading}
                      text="Assign"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default AddBudget;