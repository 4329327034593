import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'budget/'

export const BudgetApiService = createApi({
    reducerPath: 'budgetApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createBudget: builder.mutation({
            query: (payload) => {
                return {
                    url: `create`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        // updateOrder: builder.mutation({
        //     query: (payload) => {
        //         return {
        //             url: `update-order/${payload?._id}`,
        //             method: 'POST',
        //             body: payload
        //         }
        //     }
        // }),
        // deleteOrder: builder.mutation({
        //     query: (id) => {
        //         return {
        //             url: `deleteOrder/${id}`,
        //             method: 'POST'
        //         }
        //     }
        // }),
        getBudgets: builder.query({
            query: (payload) => {
                if (payload) {
                    let { createdBy, page, rowsPerPage, search, status, from, to, salesman } = payload

                    return `get?${createdBy && createdBy !== '' ? `&createdBy=${createdBy}` : ""}${page && page !== '' ? `&page=${page}` : ""}${rowsPerPage && rowsPerPage !== '' ? `&rowsPerPage=${rowsPerPage}` : ""}${search && search !== '' ? `&search=${search}` : ""}${status && status !== '' ? `&status=${status}` : ""}${from && from !== '' ? `&from=${from}` : ""}${to && to !== '' ? `&to=${to}` : ""}${salesman && salesman !== '' ? `&salesman=${salesman}` : ""}`
                } else {
                    return `get`
                }
            },
            keepUnusedDataFor: 0
        }),
        getBudgetById: builder.query({
            query: (id) => `get/${id}`
        }),
        // getSalary: builder.query({
        //     query: (payload) => `salary?id=${payload?.id}&from=${payload?.from}&to=${payload?.to}`,
        //     keepUnusedDataFor: 0
        // }),
        // addInvoice: builder.mutation({
        //     query: (payload) => {
        //         return {
        //             url: `addInvoice`,
        //             method: 'POST',
        //             body: payload
        //         }
        //     }
        // }),
        // getDashboard: builder.query({
        //     query: ({ id, period, role, to, from }) => {

        //         if (role === "admin") {
        //             return `evaluate-admin-earnings?period=${period}`
        //         }

        //         return `evaluate-earnings?id=${id}&period=${period}&from=${from}&to=${to}`
        //     },
        //     keepUnusedDataFor: 0
        // }),
        // markAsPaid: builder.mutation({
        //     query: (payload) => {
        //         return {
        //             url: `updateOrderStatus/${payload?.id}`,
        //             method: 'POST',
        //             body: payload
        //         }
        //     }
        // }),
    })
})

export const { useCreateBudgetMutation, useGetBudgetsQuery, useGetBudgetByIdQuery } = BudgetApiService