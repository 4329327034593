export const BASE_URL = "https://api.mbsthlm.se/stockholm/v1/api/"

// export const BASE_URL = "http://localhost:5000/stockholm/v1/api/"

// export const BASE_URL = "https://stockholm-backend-production.up.railway.app/stockholm/v1/api/"

// export const BASE_URL = "https://impartial-position-production.up.railway.app/stockholm/v1/api/"

export const WORKING_HOURS = 8.75
export const FRIDAY_WORKING_HOURS = 7.75
