import React from "react";
import popup from "../../../assests/popup.png"
import { FaArrowLeft } from 'react-icons/fa6';
import { Link, useParams, useRoutes } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../redux/apis/user";
import { format_date } from "../../../utils/helper";
import { useGetSalaryQuery } from "../../../redux/apis/order";


const SalesmanDetails = () => {

    const { id } = useParams()

    const { data } = useGetUserByIdQuery(id)
    // const { data: salaryData } = useGetSalaryQuery(id)

    if (data) {
        var details = data?.data
    }

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="headd1">
                            <h2 style={{ color: '#000', padding: '36px 9px 20px', fontWeight: 700, lineHeight: '40px', fontSize: '30px' }}> <Link to="/admin/salesman" className="btn back"><FaArrowLeft /></Link>Salesman Profile</h2>
                            {/* <img src={rectangle} /> */}
                        </div>
                        <div className="col-md-6">
                            <div className="texxtwrp">
                                <div className="d-flex flex-row row">
                                    <div className="col-6">
                                        <h4>Salesman Name</h4>
                                        <p>{details?.fullName}</p>
                                    </div>
                                    <div className="col-6">
                                        <h4>Salesman Email</h4>
                                        <p>{details?.email}</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row row">
                                    <div className="col-6">
                                        <h4>Salesman Phone</h4>
                                        <p>{details?.phoneNumber}</p>
                                    </div>
                                    <div className="col-6">
                                        <h4>Salesman Commission</h4>
                                        <p>{details?.commission || 0}</p>
                                    </div>
                                    <div className="col-6">
                                        <h4>Created on</h4>
                                        <p>{format_date(details?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <h3 style={{ color: '#000', padding: '36px 9px 20px', fontWeight: 700, lineHeight: '40px', fontSize: '30px' }}>Salary Details</h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12 col-sm-12  col-lg-12">
                                    <div className="oderrrr-herd">
                                        <div className="row">
                                            <div className="col">
                                                <h6>Order ID</h6>
                                            </div>
                                            <div className="col">
                                                <h6>User Name</h6>
                                            </div>
                                            <div className="col">
                                                <h6>Invoice</h6>
                                            </div>
                                            <div class="col">
                                                <h6>Order Date</h6>
                                            </div>
                                            <div className="col">
                                                <h6>Order Date</h6>
                                            </div>
                                            <div className="col">
                                                <h6>Profit</h6>
                                            </div>
                                            <div class="col">
                                                <h6>Action</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            salaryData?.data?.orders?.map((item, index) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-12 col-sm-12  col-lg-12">
                                            <div className="oderrrr">
                                                <div className="row">
                                                    <div className="col cntrrrr">
                                                        <p>01</p>
                                                    </div>
                                                    <div className="col cntrrrr">
                                                        <h6 style={{ fontSize: '18px', color: '#999999', paddingTop: '3px' }}>abc</h6>
                                                    </div>
                                                    <div className="col cntrrrr">
                                                        <p>4</p>
                                                    </div>
                                                    <div className="col cntrrrr">
                                                        <p>mm/dd/yyyy</p>
                                                    </div>
                                                    <div className="col cntrrrr ">
                                                        <p>$120</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } */}
                    </div>
                </div>
            </div></div>
    );
}
export default SalesmanDetails;