import { BrowserRouter, Route, Routes } from "react-router-dom"
import Dashboard from "../components/Dashboard"
import AppLayout from "../components/layout/AppLayout"
// Admin
import Orders from "../screens/Admin/Orders"
import OrderDetails from "../screens/Admin/Orders/OrderDetails"
import Salary from "../screens/Admin/Salary"
import Salesman from "../screens/Admin/Salesman"
import AddSalesman from "../screens/Admin/Salesman/AddSalesman"
import SalesmanDetails from "../screens/Admin/Salesman/SalesmanDetails"

// import Salary from "../components/Salary"
import ApprProfile from "../components/ApprProfile"
import EditProfile from "../components/EditProfile"
import EditProfile2 from "../components/EditProfile2"
import Notification from "../components/Notification"
// import Approval from '../components/Approval'
import AdminCustomers from "../screens/Admin/Customers"

// Salesman
import Customers from "../screens/Salesman/Customers"
import MyOrder from "../screens/Salesman/Orders"
import OrderCreation from "../screens/Salesman/Orders/OrderCreation"
import SalesmanSalaryDetails from "../screens/Salesman/Salary/SalaryDetails"

import { useSelector } from "react-redux"
import Login from '../components/Login'
import Password from '../components/Password'
import PasswordChange from '../components/PasswordChange'
import Verification from '../components/Verification'
import Attendance from "../screens/Admin/Attendance"
import AttendanceDetail from "../screens/Admin/Attendance/AttendanceDetail"
import Budget from "../screens/Admin/Budget"
import AddBudget from "../screens/Admin/Budget/AddBudget"
import EditCustomer from "../screens/Admin/Customers/EditCustomer"
import Leaves from "../screens/Admin/Leaves"
import LeavesDetails from "../screens/Admin/Leaves/LeaveDetails"
import LeaveRequests from "../screens/Admin/Leaves/LeaveRequests"
import PaidOrders from "../screens/Admin/PaidOrders"
import EmployeePaidOrders from "../screens/Employee/PaidOrders"
import SalaryDetails from "../screens/Admin/Salary/SalaryDetails"
import SalesmanAttendance from "../screens/Salesman/Attendance"
import SalesmanLeaves from "../screens/Salesman/Leave"
import SalesmanApplyLeaves from "../screens/Salesman/Leave/applyLeave"
import SalesmanBudget from "../screens/Salesman/Budget"
import EditOrder from "../screens/Salesman/Orders/EditOrder"
import PrivateRoute from "./PrivateRoute"
import ProtectedRoute from "./ProtectedRoute"
import BudgetDetails from "../screens/Admin/Budget/BudgetDetails"
import EmployeeOrderDetails from "../screens/Employee/PaidOrders/OrderDetails"
import EmployeeSalary from "../screens/Employee/Salary"
import AdminEditOrder from "../screens/Admin/Orders/EditOrder"
import BinOrder from "../screens/Admin/Orders/BinOrder"
import Comission from "../screens/Admin/Commission"
import AdminDashboardOrders from "../screens/Admin/DashboardOrders/index"
import EditSalesman from "../screens/Admin/Salesman/EditSalesman"
import DashboardAnalytics from "../components/DashboardAnalytics"



const Router = () => {

    const { user } = useSelector(state => state.userSlice)

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<PrivateRoute><AppLayout /></PrivateRoute>}>
                    <Route path='/admin' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path='/admin/salesman' element={<PrivateRoute><Salesman /></PrivateRoute>} />
                    <Route path='/admin/salesman/add' element={<PrivateRoute><AddSalesman /></PrivateRoute>} />
                    <Route path='/admin/salesman/detail/:id' element={<PrivateRoute><SalesmanDetails /></PrivateRoute>} />
                    <Route path='/admin/salesman/edit/:id' element={<PrivateRoute><EditSalesman /></PrivateRoute>} />
                    <Route path='/admin/commission' element={<PrivateRoute><Comission /></PrivateRoute>} />
                    <Route path='/admin/customers' element={<PrivateRoute><AdminCustomers /></PrivateRoute>} />
                    <Route path='/admin/customers/edit/:id' element={<PrivateRoute><EditCustomer /></PrivateRoute>} />
                    <Route path='/admin/orders' element={<PrivateRoute><Orders /></PrivateRoute>} />
                    <Route path='/admin/orders-bin' element={<PrivateRoute><BinOrder /></PrivateRoute>} />
                    <Route path='/admin/orders/edit/:id' element={<PrivateRoute><AdminEditOrder /></PrivateRoute>} />
                    <Route path='/admin/orders/detail/:id' element={<PrivateRoute><OrderDetails /></PrivateRoute>} />
                    <Route path='/admin/paid-orders' element={<PrivateRoute><PaidOrders /></PrivateRoute>} />
                    <Route path='/admin/profile' element={<PrivateRoute><ApprProfile /></PrivateRoute>} />
                    <Route path='/admin/profile/edit' element={<PrivateRoute><EditProfile /></PrivateRoute>} />
                    <Route path='/admin/profile/change-password' element={<PrivateRoute><EditProfile2 /></PrivateRoute>} />
                    <Route path='/admin/notification' element={<PrivateRoute><Notification /></PrivateRoute>} />
                    <Route path='/admin/salary' element={<PrivateRoute><Salary /></PrivateRoute>} />
                    <Route path='/admin/salary/detail/:id' element={<PrivateRoute><SalaryDetails /></PrivateRoute>} />
                    <Route path='/admin/attendance' element={<PrivateRoute><Attendance /></PrivateRoute>} />
                    <Route path='/admin/attendance/detail/:id' element={<PrivateRoute><AttendanceDetail /></PrivateRoute>} />
                    <Route path='/admin/leaves' element={<PrivateRoute><Leaves /></PrivateRoute>} />
                    <Route path='/admin/leaves/requests' element={<PrivateRoute><LeaveRequests /></PrivateRoute>} />
                    <Route path='/admin/leaves/detail/:id' element={<PrivateRoute><LeavesDetails /></PrivateRoute>} />
                    <Route path='/admin/budget' element={<PrivateRoute><Budget /></PrivateRoute>} />
                    <Route path='/admin/budget/detail/:id' element={<PrivateRoute><BudgetDetails /></PrivateRoute>} />
                    <Route path='/admin/budget/add' element={<PrivateRoute><AddBudget /></PrivateRoute>} />
                    <Route path='/admin/dashboard/orders' element={<PrivateRoute><AdminDashboardOrders /></PrivateRoute>} />
                    <Route path='/admin/dashboard/analytics' element={<PrivateRoute><DashboardAnalytics /></PrivateRoute>} />
                    <Route path='/salesman/' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path='/salesman/orders/create' element={<PrivateRoute><OrderCreation /></PrivateRoute>} />
                    <Route path='/salesman/orders/edit/:id' element={<PrivateRoute><EditOrder /></PrivateRoute>} />
                    <Route path='/salesman/orders' element={<PrivateRoute><MyOrder /></PrivateRoute>} />
                    <Route path='/salesman/orders/detail/:id' element={<PrivateRoute><OrderDetails /></PrivateRoute>} />
                    <Route path='/salesman/customers/' element={<PrivateRoute><Customers /></PrivateRoute>} />
                    <Route path='/salesman/profile' element={<PrivateRoute><ApprProfile /></PrivateRoute>} />
                    <Route path='/salesman/profile/edit' element={<PrivateRoute><EditProfile /></PrivateRoute>} />
                    <Route path='/salesman/profile/change-password' element={<PrivateRoute><EditProfile2 /></PrivateRoute>} />
                    <Route path='/salesman/notification' element={<PrivateRoute><Notification /></PrivateRoute>} />
                    <Route path='/salesman/attendance' element={<PrivateRoute><SalesmanAttendance /></PrivateRoute>} />
                    <Route path='/salesman/leaves' element={<PrivateRoute><SalesmanLeaves /></PrivateRoute>} />
                    <Route path='/salesman/leaves/apply' element={<PrivateRoute><SalesmanApplyLeaves /></PrivateRoute>} />
                    <Route path='/salesman/budget' element={<PrivateRoute><SalesmanBudget /></PrivateRoute>} />
                    <Route path='/salesman/salary' element={<PrivateRoute><SalesmanSalaryDetails /></PrivateRoute>} />
                    <Route path='/salesman/dashboard/orders' element={<PrivateRoute><AdminDashboardOrders /></PrivateRoute>} />

                    <Route path='/employee/' element={<PrivateRoute><EmployeePaidOrders /></PrivateRoute>} />
                    <Route path='/employee/orders/detail/:id' element={<PrivateRoute><EmployeeOrderDetails /></PrivateRoute>} />
                    <Route path='/employee/salary' element={<PrivateRoute><EmployeeSalary /></PrivateRoute>} />

                </Route>
                <Route path='/login' element={<ProtectedRoute><Login /></ProtectedRoute>} />
                <Route path='/forget-password' element={<ProtectedRoute><Password /></ProtectedRoute>} />
                <Route path='/verification' element={<ProtectedRoute><Verification /></ProtectedRoute>} />
                <Route path='/set-password' element={<ProtectedRoute><PasswordChange /></ProtectedRoute>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router