import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'orders/'

export const OrderApiService = createApi({
    reducerPath: 'orderApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (payload) => {
                return {
                    url: `create`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        updateOrder: builder.mutation({
            query: (payload) => {
                return {
                    url: `update-order/${payload?._id}`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        deleteOrder: builder.mutation({
            query: (id) => {
                return {
                    url: `deleteOrder/${id}`,
                    method: 'POST'
                }
            }
        }),
        deleteOrderBin: builder.mutation({
            query: (payload) => {
                return {
                    url: `deleteOrderBin/${payload.id}?type=${payload.type}`,
                    method: 'POST'
                }
            }
        }),
        getOrders: builder.query({
            query: (payload) => {
                if (payload) {
                    let { createdBy, page,category, rowsPerPage, search, status, from, to, newCustomer } = payload

                    return `all?${createdBy && createdBy !== '' ? `&createdBy=${createdBy}` : ""}${page && page !== '' ? `&page=${page}` : ""}${rowsPerPage && rowsPerPage !== '' ? `&rowsPerPage=${rowsPerPage}` : ""}${search && search !== '' ? `&search=${search}` : ""}${status && status !== '' ? `&status=${status}` : ""}${from && from !== '' ? `&from=${from}` : ""}${to && to !== '' ? `&to=${to}` : ""}${newCustomer !== undefined && newCustomer !== '' ? `&newCustomer=${newCustomer}` : ""} ${category !== undefined && category !== '' ? `&category=${category}` : ""}`
                } else {
                    return `all`
                }
            },
            keepUnusedDataFor: 0
        }),
        getUnactiveOrders: builder.query({
            query: (payload) => {
                if (payload) {
                    let { createdBy, page, rowsPerPage, search, status, from, to, newCustomer } = payload

                    return `all-unactive?${createdBy && createdBy !== '' ? `&createdBy=${createdBy}` : ""}${page && page !== '' ? `&page=${page}` : ""}${rowsPerPage && rowsPerPage !== '' ? `&rowsPerPage=${rowsPerPage}` : ""}${search && search !== '' ? `&search=${search}` : ""}${status && status !== '' ? `&status=${status}` : ""}${from && from !== '' ? `&from=${from}` : ""}${to && to !== '' ? `&to=${to}` : ""}${newCustomer !== undefined && newCustomer !== '' ? `&newCustomer=${newCustomer}` : ""}`
                } else {
                    return `all-unactive`
                }
            },
            keepUnusedDataFor: 0
        }),
        getOrderById: builder.query({
            query: (id) => `details/${id}`
        }),
        getSalary: builder.query({
            query: (payload) => `salary?id=${payload?.id}&from=${payload?.from}&to=${payload?.to}`,
            keepUnusedDataFor: 0
        }),
        getEmployeeSalary: builder.query({
            query: (payload) => `calculateAddAdminSalary?id=${payload?.id}&from=${payload?.from}&to=${payload?.to}`,
            keepUnusedDataFor: 0
        }),
        addInvoice: builder.mutation({
            query: (payload) => {
                return {
                    url: `addInvoice`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        deleteInvoice: builder.mutation({
            query: (id) => {
                return {
                    url: `deleteInvoice/${id}`,
                    method: 'POST'
                }
            }
        }),
        getDashboard: builder.query({
            query: ({ id, period, role, to, from }) => {

                if (role === "admin") {
                    return `evaluate-admin-earnings?period=${period}`
                }

                return `evaluate-earnings?id=${id}&period=${period}&from=${from}&to=${to}`
            },
            keepUnusedDataFor: 0
        }),
        markAsPaid: builder.mutation({
            query: (payload) => {
                return {
                    url: `updateOrderStatus/${payload?.id}`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
    })
})

export const { useGetOrdersQuery, useDeleteInvoiceMutation, useGetEmployeeSalaryQuery, useDeleteOrderMutation,useDeleteOrderBinMutation, useUpdateOrderMutation, useGetDashboardQuery, useMarkAsPaidMutation, useCreateOrderMutation, useGetOrderByIdQuery, useGetSalaryQuery, useAddInvoiceMutation,useGetUnactiveOrdersQuery } = OrderApiService
