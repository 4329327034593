import { createSlice } from '@reduxjs/toolkit'
import { GeneralApiService } from '../apis/general'

const initialState = {
    settings: null,
    notifications: [],
    unreadCount: 0
}

const generalSlice = createSlice({
    name: 'generalSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(GeneralApiService.endpoints.getSettings.matchFulfilled, (state, action) => {
            let { data } = action.payload
            state.settings = data
        })
        builder.addMatcher(GeneralApiService.endpoints.getNotification.matchFulfilled, (state, action) => {
            let { data, unreadCount } = action.payload
            state.notifications = data
            state.unreadCount = unreadCount
        })
    }
})

export const { } = generalSlice.actions
export default generalSlice.reducer