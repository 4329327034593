import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useCreateOrderMutation,
  useUpdateOrderMutation,
} from "../../../redux/apis/order";
import { useLazyFindCustomerQuery } from "../../../redux/apis/customer";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const products = [
  {
    label: "Google Ads",
    value: "google-adds",
  },
  {
    label: "SEO",
    value: "seo",
  },
  {
    label: "Website",
    value: "website",
  },
];

const EditOrder = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  if (!state?.order) {
    navigate("/salesman/orders");
  }

  const defaultValues = {
    orderCreatedAt: moment(state?.order?.orderCreatedAt)
      .format("YYYY-MM-DD")
      .toString(),
    productName: state?.order?.productName,
    number: state?.order?.number,
    agreementPeriod: state?.order?.agreementPeriod,
    orderValue: state?.order?.orderValue,
    otherRequests: state?.order?.otherRequests,
    old_link: state?.order?.old_link,
    customer_services: state?.order?.customer_services,
    haveDomain: state?.order?.haveDomain,
    customer_domain: state?.order?.customer_domain,
    customer_type: state?.order?.customer_type,
    customer_social_media: state?.order?.customer_social_media,
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ defaultValues });

  let selectedProduct = watch("productName");

  const [findCustomer, { data, isSuccess }] = useLazyFindCustomerQuery();
  const [updateOrder, { data: orderData, isSuccess: orderCreated, isLoading }] =
    useUpdateOrderMutation();

  const onEnterOrganizationNumber = () => {
    findCustomer(getValues("organisationNumber"));
  };

  const onSubmit = (data) => {
    updateOrder({
      ...data,
      _id: state?.order?._id,
      orderCreatedAt: new Date(data?.orderCreatedAt),
    });
  };
console.log(state?.order,"data");
  useEffect(() => {
    if (isSuccess) {
      setValue("fullName", data?.customer?.fullName);
      setValue("email", data?.customer?.email);
      setValue("old_link", state?.order?.old_link);
    } else {
      setValue("fullName", "");
      setValue("email", "");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (orderCreated) {
      navigate("/salesman/orders");
      toast.success("Order Updated Successfully");
    }
  }, [orderCreated]);

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: "10px 10px 50px" }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2 style={{ padding: "36px 9px 20px" }}>Edit Order</h2>
                  {/* <div className="btn-ordr">
                    <a className="btnn-112" href="/myorder">My orders</a>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <InputField
                      label="Organization Number"
                      placeholder="Enter Organization Number"
                      required
                      value={state?.order?.customer?.organisationNumber}
                      disabled
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <InputField
                      label="Customer Name"
                      placeholder="Enter Customer Name"
                      required
                      value={state?.order?.customer?.fullName}
                      disabled
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <InputField
                      label="Customer Email"
                      placeholder="Enter Customer Email"
                      required
                      value={state?.order?.customer?.email}
                      disabled
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="orderCreatedAt"
                      control={control}
                      rules={{
                        required: "Order Date is required",
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Order Date"
                          placeholder="Enter Order Date"
                          type="date"
                          error={errors?.orderCreatedAt?.message}
                          required
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="productName"
                      control={control}
                      rules={{
                        required: "Product is required",
                      }}
                      render={({ field }) => (
                        <div className="md-form">
                          <h6>
                            Product <span className="text-danger">*</span>
                          </h6>
                          <select className="form-control" {...field}>
                            {products.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </select>
                        </div>
                      )}
                    />
                  </div>
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="old_link"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer old webbpage link"
                            placeholder="Customer old webbpage link"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_services"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customers services"
                            placeholder="Customers services"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                   {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="haveDomain"
                        control={control}
                        render={({ field }) => (
                          <div className="md-form">
                            <h6>
                              Have Domain? <span className="text-danger">*</span>
                            </h6>
                            <select className="form-control" {...field}>
                              <option value="yes" defaultChecked>YES</option>
                              <option value="no">NO</option>
                            </select>
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_domain"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer Domain"
                            placeholder="Customer Domain"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                 
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_type"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customers type"
                            placeholder="Customers type"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
                  {selectedProduct === "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="customer_social_media"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customers Social media"
                            placeholder="Customers Social media"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                  )}
               
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="refPerson"
                        control={control}
                        // rules={{
                        //   required: "Customer Email is required"
                        // }}
                        render={({ field }) => (
                          <InputField
                            label="Customer Ref Person"
                            placeholder="Enter Customer Ref Person"
                            error={errors?.refPerson?.message}
                            // required
                            {...field}
                            disabled={isSuccess}
                          />
                        )}
                      />
                    </div>
                
                  {selectedProduct !== "website" && (
                    <div className="col-sm-5 ofset-7">
                      <Controller
                        name="number"
                        control={control}
                        rules={{
                          required: "No of clicks is required",
                        }}
                        render={({ field }) => (
                          <InputField
                            label={
                              selectedProduct !== "seo"
                                ? "Number of Clicks"
                                : "Number of Keywords"
                            }
                            placeholder={`Enter ${
                              selectedProduct !== "seo"
                                ? "Number of Clicks"
                                : "Number of Keywords"
                            }`}
                            error={errors?.number?.message}
                            required
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}
                  {/* <div className="col-sm-5 ofset-7">
                    <Controller
                      name="number"
                      control={control}
                      rules={{
                        required: "No of clicks is required",
                      }}
                      render={({ field }) => (
                        <InputField
                          label={
                            selectedProduct !== "SEO"
                              ? "Number of Clicks"
                              : "Number of Keywords"
                          }
                          placeholder={`Enter ${
                            selectedProduct !== "SEO"
                              ? "Number of Clicks"
                              : "Number of Keywords"
                          }`}
                          error={errors?.number?.message}
                          required
                          {...field}
                        />
                      )}
                    />
                  </div> */}
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="agreementPeriod"
                      control={control}
                      rules={{
                        required: "Agreement Period is required",
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Agreement Period (Monthly)"
                          placeholder="Enter Agreement Period"
                          error={errors?.agreementPeriod?.message}
                          required
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="orderValue"
                      control={control}
                      rules={{
                        required: "Order Value is required",
                      }}
                      render={({ field }) => (
                        <InputField
                          disabled
                          label="Order Value"
                          placeholder="Enter Order Value"
                          error={errors?.orderValue?.message}
                          required
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="otherRequests"
                      control={control}
                    //   rules={{
                    //     required: "Other Request is required",
                    //   }}
                      render={({ field }) => (
                        <InputField
                          label="Other Requests"
                          placeholder="Enter Other Request"
                          required
                          error={errors?.otherRequests?.message}
                          type="textarea"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12">
                    <Button
                      text="Save"
                      onClick={handleSubmit(onSubmit)}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EditOrder;
