import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const PrivateRoute = ({ children }) => {

    const navigate = useNavigate()
    const { loggedIn, user } = useSelector(state => state.userSlice)

    useEffect(() => {
        if (!loggedIn) {
            navigate("/login")
        } else if (loggedIn && !(window.location.pathname.includes(`/${user?.role}/`))) {
            navigate(`/${user?.role}/`)
        }
    }, [loggedIn, user])

    if (loggedIn) {
        return children
    }

    return;
}

export default PrivateRoute